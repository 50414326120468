import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { imageUrl } from "../../../services/Constants";
import * as requestActions from "../../../actions/request.action";
import * as requestDeleteActions from "../../../actions/request.delete.actions";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import styled from '@emotion/styled';
import {
    Stack,
    IconButton,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    CardContent,
    Card,
    TextField
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../..";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { server, TOKEN } from "../../../services/Constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue, red } from "@mui/material/colors";
import "./RequestPage.css";
import { Search as SearchIcon, Tune } from "@mui/icons-material";
import Container from '@mui/material/Container';
import * as loginActions from "../../../actions/login.action";
import * as menuconfigActions from "../../../actions/menuconfig.action";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
// import SearchBar from "material-ui-search-bar";
// const CustomToolbar = (props:any) => {
//     return (
//         <Box>
//             <Grid>
//                 <SearchBar {...props} />
//             </Grid>
//         </Box>
//     )
// }

import Swal from "sweetalert2";

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

export default function RequestPage(): JSX.Element {
    const requesReducer = useSelector((state: RootReducers) => state.requesReducer);
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const [selectedRequest, setSelectedRequest] = React.useState<any>({});
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [count_request, setCountRequest] = React.useState<number>(0);
    const [count_operation, setCountOperation] = React.useState<number>(0);
    const [count_succes, setCountSucces] = React.useState<number>(0);
    const navigate = useNavigate();
    
    React.useEffect(() => {
        if (!localStorage.getItem(TOKEN)) {
        //   navigate("/login");
          dispatch(loginActions.logouttologinpage(navigate));
        }
        dispatch(menuconfigActions.getMENU_CONFIGCheckTokenOnly(navigate));
    }, []);

    //load data table
    React.useEffect(() => {
        dispatch(requestActions.loadRequestName());

    }, []);
    React.useEffect(() => {
        setCountRequest(requesReducer.res_count)
        setCountOperation(requesReducer.oper_count)
        setCountSucces(requesReducer.sucess_count)

    }, [requesReducer]);

    const requestColumns: GridColDef[] = [
        {
            headerName: `${t('Request No.')}`,
            field: "request_no",
            flex: 1 ,
            minWidth: 100
            
           
        },
        {
            headerName: `${t('Date')}`,
            field: "create_date",
            flex: 1,
            minWidth: 100
        },
        {
            headerName: `${t('Due Date')}`,
            field: "request_date",
            flex: 1,
            minWidth: 100
        },
        {
            headerName: `${t('Firstname')} ${t('Lastname')}`,
            field: "name",
            flex: 2,
            minWidth: 150
        },
        {
            headerName: `${t('Request Type')}`,
            field: "request_name_show",
            flex: 2,
            minWidth: 150
        },
        {
            headerName: `${t('Status')}`,
            field: "status_name",  
            flex: 1,
            minWidth: 100         
        },
        {
            headerName: "",
            field: ".",
            maxWidth: 150,
            align: "center",
            renderCell: ({ row }: GridRenderCellParams<string>) => (
                <Stack direction="row">
                    {
                        row.status_name == "Draft" ||
                        row.status_name == "ร่าง" ? 
                        <>
                            <IconButton
                                aria-label="edit"
                                size="large"
                                onClick={() => {
                                    navigate("/edit-request/" + row.id);

                                }}
                            >
                                <EditIcon 
                                    fontSize="inherit" 
                                    sx={{ color: blue[700] }}  
                                />
                            </IconButton>
                            <IconButton
                                disabled={row.status == "Close" || row.status == "Cancel" }
                                aria-label="delete"
                                size="large"
                                onClick={() => {
                                    // console.log(row)
                                    setSelectedRequest(row);
                                    Swal.fire({
                                        title: `${t('Delete Data')}`,
                                        text: `${t('Confirm Delete Data')} ${t('Request No.')} ${row.request_no} ?`,
                                        icon: "warning",
                                        // showDenyButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: `${t('Delete')}`,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        cancelButtonText: `${t('Cancel')}`,
                                        // denyButtonText: `ไม่`,
                                      }).then((result) => {
                                        /* Read more about isConfirmed, isDenied below */
                                        if (result.isConfirmed) {
                                            handleDeleteConfirm(row.id)
                                          // Swal.fire("Saved!", "", "info");
                                        }
                                        // else if (result.isDenied) {
                                        //   Swal.fire("Changes are not saved", "", "info");
                                        // }
                                      });
                                    // setOpenDialog(true);
                                }}
                            >
                                <DeleteIcon 
                                    fontSize="inherit" 
                                    sx={{ color: red[500] }}
                                />
                            </IconButton>
                        </>    
                    :   
                        <>
                            <Button 
                                variant="contained"
                                onClick={ () =>{
                                    navigate("/my-request-select/" + row.id)
                                }}
                                size="small"
                                sx={{
                                    width: 80,
                                    backgroundColor: "#73777B",
                                    '&:hover': {backgroundColor: '#2C394B'},
                                }}
                            >
                                    {t(`Select`)}
                            </Button>
                        </>
                    }
                </Stack>
            ),
        },
    ];
    
    //search
    const [isSelected1, setIsSelected1] = React.useState(false);
    const [isSelected2, setIsSelected2] = React.useState(false);
    const [isSelected3, setIsSelected3] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [searchText1, setSearchText1] = React.useState("");
    const [tableData, setTableData] = React.useState<any[]>([]);
    const [tableDataS, setTableDataS] = React.useState<any[]>([]);

    React.useEffect(() => {
        // alert(i18next.language)
        let  dataList:any[] = [];
        let index = 0;
        requesReducer.result.forEach((item) =>{
            if(Number(item.status) == 1){
                item.status_name = `${t('Draft')}`
            }else if(Number(item.status) == 2){
                item.status_name = `${t(`Submitted`)}`
            }else if(Number(item.status) == 3){
                item.status_name = `${t('In Progress')}`
            }else if(Number(item.status) == 4){
                item.status_name = `${t(`Done`)}`
            }else if(Number(item.status) == 5){
                item.status_name = `${t('Cancel')}`
            }else if(Number(item.status) == 6){
                item.status_name = `${t('Overdue')}`
            }else if(Number(item.status) == 7){
                item.status_name = `${t('Needs Action')}`

            }else if(Number(item.status) == 8){
                item.status_name = `${t('Submitted')}`
            }else if(Number(item.status) == 9){
                item.status_name = `${t('Closed')}`
            }
            if(i18next.language == 'en'){
                item.request_name_show = item.request_type_name_en;
            }else if(i18next.language == 'th'){
                item.request_name_show = item.request_type_name;
            }else{
                return;
            }
            dataList[index] = item;
            index += 1;
        })
        console.log("setTableData")
        console.log(dataList)
        setTableData(dataList)
        setTableDataS(dataList)
    }, [requesReducer.result,i18next.language]);

    const requestSearch = (searchValue: string) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = requesReducer.result.filter((o: any) => {
          return Object.keys(o).some((k: any) => {
            return searchRegex.test(o[k].toString());
          });
        });
        setTableData(filteredRows);
    };

    // const cancelSearch = () => {
    //     setSearchText("");
    //     requestSearch(searchText);
    // };
    const handleDeleteConfirm = (request_id:number) => {
        dispatch(requestDeleteActions.deleteRequestList(request_id,navigate,String("N")));
        setOpenDialog(false);
        window.location.reload();
    };
    const handleCardClick = (status:number) => {
        let dataSearchList:any = [] ;
        let check_click = false;
            if(status == 3 || status == 4){
                if(status == 3 ){
                    check_click = isSelected2;
                    if(isSelected2){
                        setIsSelected1(false);
                        setIsSelected2(false);
                        setIsSelected3(false);
                    }else{
                        setIsSelected1(false);
                        setIsSelected2(true);
                        setIsSelected3(false);
                    }
                }
                if(status == 4){
                    check_click = isSelected3;
                    if(isSelected3){
                        setIsSelected1(false);
                        setIsSelected2(false);
                        setIsSelected3(false);
                    }else{
                        setIsSelected1(false);
                        setIsSelected2(false);
                        setIsSelected3(true);
                    }
                }
                if(check_click){
                    dataSearchList = tableDataS;
                }else{
                    tableDataS.forEach((item)=>{
                        if(Number(item.status_show) == Number(status)){
                            dataSearchList.push(item)
                        }
                    })
                }
            }else if(status == 0){
                if(isSelected1){
                    setIsSelected1(false);
                    setIsSelected2(false);
                    setIsSelected3(false);
                }else{
                    setIsSelected1(true);
                    setIsSelected2(false);
                    setIsSelected3(false);
                }
                dataSearchList = tableDataS;
            }


        
        setTableData(dataSearchList)
    };
    const StyledCard = styled(Card)`
        transition: border 0.3s;
        &:hover {
            border: 2px solid blue;
        }
        cursor: pointer;
      
        ${(props: { isSelected: boolean }) =>
          props.isSelected &&
          `
          border: 2px solid blue;
        `}

        `;
    const showDialog = () => {
        if (selectedRequest === null) {
            return "";
        }

    return (
        <Dialog
            open={openDialog}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">

            <br />
                ยืนยันการลบรายการคำขอ : {selectedRequest.request_no}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">คุณไม่สามารถกู้คืนรายการคำขอที่ถูกลบได้</DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="info">
                ยกเลิก
            </Button>
            <Button onClick={() => handleDeleteConfirm(selectedRequest.id)} color="primary">
                ยืนยัน
            </Button>
            </DialogActions>
        </Dialog>
        );
    };

    return (
        <Container fixed sx={{ mt: 3}} >
        <Box 
            sx={{ }}
        >
            {/* Summary Icons */}
            <Grid 
            container item
            justifyContent="center"
            alignItems="center"
            sx={{
                margin: 'auto',
                width: '100%',
                maxWidth: '60rem'
            }}
            >
                <Grid display="flex" justifyContent="center" alignItems="center" xs={8} sm={4} md={3} lg={3} xl={3}>
                    <StyledCard 
                        id = "cardAll"
                        isSelected={isSelected1}
                        onClick={
                            () => handleCardClick(0)
                        }
                        variant="outlined" 
                        sx={{
                            width: '100%',
                            border: '#CE9461 solid 2px',
                            borderRadius: 0,
                            color: '#73777B',
                            margin: '1rem'
                        }}
                    >
                        <CardContent 
                            className="CardContent" 
                            sx={{ 
                                padding: '0.5rem',
                                textAlign: 'center',
                                backgroundColor: '#FDEEDC',
                            }}
                        >
                            <Typography variant="h3" component="span">
                                {count_request}
                            </Typography>
                            <Typography variant="body1">
                                {t(`All Requests`)}
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid display="flex" justifyContent="center" alignItems="center" xs={8} sm={4} md={3} lg={3} xl={3}>
                    <StyledCard variant="outlined"
                        isSelected={isSelected2}
                        id = "cardoper"
                        onClick={
                            () => handleCardClick(3)
                        }
                        sx={{
                            width: '100%',
                            border: '#FEB139 solid 2px',
                            borderRadius: 0,
                            color: '#73777B',
                            margin: '1rem'
                        }}
                    >
                        <CardContent className="CardContent"
                        sx={{ 
                            padding: '0.5rem',
                            textAlign: 'center',
                            backgroundColor: '#FFF4CF',
                        }}>
                        <Typography variant="h3" component="span">
                            {count_operation}
                        </Typography>
                        <Typography variant="body1">
                            {t(`In Progress`)}
                        </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid display="flex" justifyContent="center" alignItems="center" xs={8} sm={4} md={3} lg={3} xl={3}>
                    <StyledCard 
                        id = "cardDone"
                        variant="outlined"
                        isSelected={isSelected3}
                        onClick={        
                            () => {
                                handleCardClick(4);
                            }
                        }
                        // onMouseOver={
                        //     () => alert("testF")
                        // }
                        // onMouseOut={ 
                        //     () => alert("testF")
                        // }
                        sx={{
                            width: '100%',
                            border: '#73777B solid 2px',
                            borderRadius: 0,
                            color: '#73777B',
                            margin: '1rem',

                        }}
                    >
                        <CardContent className="CardContent"
                        sx={{ 
                            padding: '0.5rem',
                            textAlign: 'center',
                            backgroundColor: '#D6E4E5',
                        }}
                        >
                        <Typography variant="h3" component="span">
                            {count_succes}
                        </Typography>
                        <Typography variant="body1">
                            {t(`Done`)}
                        </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end">
                <Button 
                    size="large"
                    variant="contained" 
                    sx={{
                        marginTop: 2,
                        marginLeft:2,
                        marginRight: 2,
                        width: 200,
                        backgroundColor: "#254E58"
                    }}
                    className="requestButton"
                    onClick={() => {
                        navigate("/new-request")
                    }}
                >
                    {t(`New Request`)} 
                </Button>
            </Grid>
            <br />

            <Grid container >
    
                <Grid item xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                >
                    <TextField 
                        label={t('Search...')}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            endAdornment: (<IconButton><SearchIcon /></IconButton>)
                        }}
                        onChange={(e)=>{
                            requestSearch(e.target.value)
                            setSearchText(e.target.value)
                        }} 
                        fullWidth
                        sx={{
                            "& .MuiInput-underline:before": {
                                borderBottom: 1,
                                borderColor: "divider",
                            },
                            marginBottom:2
                        }}
                    />
                </Grid>
                <Grid item xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                ></Grid>
            </Grid>
            <Grid container >
    
                <Grid item xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                <DataGrid
                    // components={{ Toolbar: CustomToolbar }}
                    sx={{ backgroundColor: "white"}}  
                    rows={tableData}
                    columns={requestColumns}
                    // componentsProps={{
                    //     toolbar: {
                    //       value: searchText,
                    //       onChange: (searchVal: string) => requestSearch(searchVal),
                    //       onCancelSearch: () => cancelSearch()
                    //     }
                    // }}
                    autoHeight
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                />

                <br />
                <br />
                {showDialog()}
                </Grid>
            </Grid>
        </Box>
    </Container>
  );
}
