// import { history } from "../index";
import {
    RESET_CHECK_PASSWORD_MATCH_SUCCESS,
    RESET_CHECK_PASSWORD_MATCH_FAILED,
    RESET_UPDATE_NEW_PASSWORD_SUCCESS,
    RESET_UPDATE_NEW_PASSWORD_FAILED,
    server,
  } from "../services/Constants";
  import { httpClient } from "../services/httpclient";
  import i18n from 'i18next';
  import * as loginActions from "../actions/login.action";
  const Swal = require('sweetalert2')

  export const setResetCheckPassWordMatchSuccessToState = () => ({
    type: RESET_CHECK_PASSWORD_MATCH_SUCCESS,
  });
  export const setResetCheckPassWordMatchFailedToState = () => ({
    type: RESET_CHECK_PASSWORD_MATCH_FAILED,
  });
  export const setResetUpdateNewPassWordSuccessToState = () => ({
    type: RESET_UPDATE_NEW_PASSWORD_SUCCESS,
  });
  export const setResetUpdateNewPassWordFailedToState = () => ({
    type: RESET_UPDATE_NEW_PASSWORD_FAILED,
  });

  export const checkPassMatch = (member_id: any, payload: any, navigate:any ) => {
    return async (dispatch: any) => {
        if(payload.new_password == payload.confirm_password){
          dispatch(setResetCheckPassWordMatchSuccessToState());
          try {
            const result = await httpClient.put<any>(`${server.MEMBER_URL}/resetPassword/${member_id}`,{"password" : payload.new_password});
            if(result.status == 200){
                dispatch(setResetUpdateNewPassWordSuccessToState());
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: `${i18n.t(`Change New Password Success`)}`,
                  showConfirmButton: false,
                  timer: 1500
                })
                navigate("/login");
            }else{
              dispatch(setResetUpdateNewPassWordFailedToState());
            }
          } catch (error) {
            dispatch(setResetUpdateNewPassWordFailedToState());
          }
        }else{
          dispatch(setResetCheckPassWordMatchFailedToState());
        }
    };
  };

  export const Resetpassword = (data: any, navigate:any ) => {
    return async (dispatch: any) => {
          dispatch(setResetCheckPassWordMatchSuccessToState());
          try {
            const result = await httpClient.put(server.User + `/reset_password/${data.id}`,data);
            if(result.status == 200 || result.status == 201){
                Swal.fire({
                  title: `${i18n.t(`Edit Data`)}`,
                  text: `${i18n.t(`Edit Data Success`)}`,
                  icon: 'success',
                });
                dispatch(loginActions.logouttologinpage(navigate));
                // dispatch(setResetUpdateNewPassWordSuccessToState());
            }else if(result.status == 204){
              Swal.fire({
                title: `${i18n.t(`Edit Data`)}`,
                text: `${i18n.t(`Password Incorrect`)}`,
                icon: 'error',
              });
            }
            else{
              dispatch(setResetUpdateNewPassWordFailedToState());
            }
          } catch (error) {
            dispatch(setResetUpdateNewPassWordFailedToState());
          }
        
    };
  };
  
 
  