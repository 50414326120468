import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { imageUrl } from "../../../../services/Constants";
import * as generalMenuActions from "../../../../actions/generalmenu.action";
import * as menuconfigActions from "../../../../actions/menuconfig.action";
import * as requestDeleteActions from "../../../../actions/request.delete.actions";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../../reducers";
import {
    Stack,
    IconButton,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    CardContent,
    Card,
    TextField
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../..";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { server, TOKEN } from "../../../../services/Constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue, red } from "@mui/material/colors";
import "./GeneralMenu.css";
import { Search as SearchIcon } from "@mui/icons-material";
import Container from '@mui/material/Container';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu";
import * as loginActions from "../../../../actions/login.action";
// import SearchBar from "material-ui-search-bar";
// const CustomToolbar = (props:any) => {
//     return (
//         <Box>
//             <Grid>
//                 <SearchBar {...props} />
//             </Grid>
//         </Box>
//     )
// }



interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}


type GeneralMenuProps = {
  //
};

const GeneralMenu: React.FC<any> = () => {
  const generalMenuStateReducer = useSelector((state: RootReducers) => state.generalMenuStateReducer);
  const menuconfigReducer = useSelector((state: RootReducers) => state.menuconfigStateReducer);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedGeneralMenu, setSelectedGeneralMenu] = React.useState<any>({});
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dataAddGeneralMenu, setdataAddGeneralMenu] = React.useState({menu_name:"",menu_route:""});
  const [dataEditGeneralMenu, setdataEditGeneralMenu] = React.useState({id:0,menu_name:"",menu_route:""});
  
  const [openaddGeneralMenu, setOpenaddGeneralMenu] = React.useState(false);
  const [openeditGeneralMenu, setOpeneditGeneralMenu] = React.useState(false);

  const Alllanguages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'Thai'
    }
  ];

  const handleAddGeneralMenu = () => {
    setdataAddGeneralMenu({menu_name:"",menu_route:""})
    console.log("add");
    setOpenaddGeneralMenu(true);
};

const handleEditGeneralMenu = (data:any) => {
    setdataEditGeneralMenu({id:data.id,menu_name:data.menu_name,menu_route:data.menu_route});
    console.log("edit");
    setOpeneditGeneralMenu(true);
};


  const handleCloseaddGeneralMenu = () => {
    setOpenaddGeneralMenu(false);
  };

  const handleCloseeditGeneralMenu = () => {
    setOpeneditGeneralMenu(false);
  };

  const handleAddGeneralMenuSubmit = (event:any) => {
    event.preventDefault();
    console.log("SubmitAdd");
    dispatch(
        generalMenuActions.addGeneralMenu(
            dataAddGeneralMenu,
          navigate
        )
      );
  };
  const handleEditGeneralMenuSubmit = (event:any) => {
    event.preventDefault();
    console.log("SubmitEdit");
    dispatch(
        generalMenuActions.editGeneralMenu(
            dataEditGeneralMenu,
          navigate
        )
      );
  };

  React.useEffect(() => {
    if (generalMenuStateReducer.isAdddata) {
        Swal.fire({
            title: `${t("Add Data")}`,
            text: `${t("Add Data Success")}`,
            icon: "success",
          });
          setOpenaddGeneralMenu(false);
          dispatch(generalMenuActions.setGENERAL_MENUFetchingToState());
          dispatch(generalMenuActions.loadGENERAL_MENU());
      return;
    } else if(generalMenuStateReducer.isAddError) {
        Swal.fire({
            title: `${t("Add Data")}`,
            text: `${t("Add Data Failed")}`,
            icon: "error",
          });
          setOpenaddGeneralMenu(false);
        dispatch(generalMenuActions.setGENERAL_MENUFetchingToState());
        dispatch(generalMenuActions.loadGENERAL_MENU());
     
    }else if(generalMenuStateReducer.isEditdata) {
        Swal.fire({
            title: `${t("Edit Data")}`,
            text: `${t("Edit Data Success")}`,
            icon: "success",
          });
          setOpeneditGeneralMenu(false);
        dispatch(generalMenuActions.setGENERAL_MENUFetchingToState());
        dispatch(generalMenuActions.loadGENERAL_MENU());
     
    }else if(generalMenuStateReducer.isEditError) {
        Swal.fire({
            title: `${t("Edit Data")}`,
            text: `${t("Edit Data Failed")}`,
            icon: "error",
          });
          setOpeneditGeneralMenu(false);
        dispatch(generalMenuActions.setGENERAL_MENUFetchingToState());
        dispatch(generalMenuActions.loadGENERAL_MENU());
     
    }
    
    else if(generalMenuStateReducer.isDeletedata) {
        Swal.fire({
            title: `${t("Delete Data")}`,
            text: `${t("Delete Data Success")}`,
            icon: "success",
          });
        dispatch(generalMenuActions.setGENERAL_MENUFetchingToState());
        dispatch(generalMenuActions.loadGENERAL_MENU());
     
    }else if(generalMenuStateReducer.isDeleteError) {
        Swal.fire({
            title: `${t("Delete Data")}`,
            text: `${t("Delete Data Failed")}`,
            icon: "error",
          });
        dispatch(generalMenuActions.setGENERAL_MENUFetchingToState());
        dispatch(generalMenuActions.loadGENERAL_MENU());
     
    }else {
    }
  }, [generalMenuStateReducer]);
  
 

  //load data table
  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      dispatch(loginActions.logouttologinpage(navigate));
    }
    dispatch(menuconfigActions.getMENU_CONFIGCheckRouteConfigmenu(window.location.pathname.replace("/", "").split("/")[0],navigate));
      dispatch(generalMenuActions.loadGENERAL_MENU());
      
      // menuconfigReducer
  }, []);

  React.useEffect(() => {

  }, [generalMenuStateReducer]);

  const requestColumns: GridColDef[] = [
      {
          headerName: `${t('ID')}`,
          field: "id",
          flex: 1,
          minWidth: 50 
      },
      {
          headerName: `${t("Menu Name")}`,
          field: "menu_name",
          flex: 1,
          minWidth: 150 
      },
      {
        headerName: `${t("Menu route")}`,
        field: "menu_route",
        flex: 1,
        minWidth: 150 
    },
      
      {
          headerName: "",
          field: ".",
          maxWidth: 150,
          align: "center",
          renderCell: ({ row }: GridRenderCellParams<string>) => (
              <Stack direction="row">
                  <IconButton
                      aria-label="edit"
                      size="large"
                      onClick={() => {
                        handleEditGeneralMenu(row);
                      }}
              >
                      <EditIcon 
                          fontSize="inherit" 
                          sx={{ color: blue[700] }}  
                      />
                  </IconButton>
                  <IconButton
                      disabled={row.status == "Close" || row.status == "Cancel" }
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                          // console.log(row)
                          setSelectedGeneralMenu(row);
                          setOpenDialog(true);
                      }}
                  >
                      <DeleteIcon 
                          fontSize="inherit" 
                          sx={{ color: red[500] }}
                      />
                  </IconButton>
              </Stack>
            ),
      },
  ];

  //search
  const [searchText, setSearchText] = React.useState("");
  const [searchText1, setSearchText1] = React.useState("");
  const [tableData, setTableData] = React.useState<any[]>([]);

  React.useEffect(() => {
      let  dataList:any[] = [];
      let index = 0;
      generalMenuStateReducer.result.forEach((item) =>{
          dataList[index] = item;
          index += 1;
      })
      setTableData(dataList)
  }, [generalMenuStateReducer.result]);

  const requestSearch = (searchValue: string) => {
      // const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
      // const filteredRows = generalMenuStateReducer.result.filter((o: any) => {
      //   return Object.keys(o).some((k: any) => {
      //     return searchRegex.test(o[k].toString());
      //   });
      // });
      const filteredRows = generalMenuStateReducer.result.filter((row) => {
        return row.menu_name?.toString().toLowerCase().includes(searchValue.toLowerCase());
      });
      setTableData(filteredRows);
  };

  // const cancelSearch = () => {
  //     setSearchText("");
  //     requestSearch(searchText);
  // };
  const handleDeleteConfirm = (GENERAL_MENU_id:number) => {
      dispatch(generalMenuActions.deleteGeneralMenu(GENERAL_MENU_id,navigate));
      setOpenDialog(false);
  };



  const showDialog = () => {
      if (selectedGeneralMenu === null) {
          return "";
      }
  return (
      <Dialog
      fullWidth
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle id="alert-dialog-slide-title">
            {t("Delete Data")}
             
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">  {t("Confirm Delete Data")} : {selectedGeneralMenu.menu_name}</DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="info">
              ยกเลิก
          </Button>
          <Button onClick={() => handleDeleteConfirm(selectedGeneralMenu.id)} color="primary">
              ยืนยัน
          </Button>
          </DialogActions>
      </Dialog>
      );
  };

  const showDialogAddGeneralMenu = () => {
   
return (
  <Dialog
  disableScrollLock
  fullWidth
  open={openaddGeneralMenu}
  onClose={handleCloseaddGeneralMenu}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
<form onSubmit={handleAddGeneralMenuSubmit}>
  <DialogTitle id="alert-dialog-title" >
  {t("Add Menu")} 
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
    </DialogContentText>
    <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center"}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Menu Name')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email_address"
          size="small"
          onChange={(e) => {
                setdataAddGeneralMenu({...dataAddGeneralMenu,menu_name:e.target.value});
          
           
          }}
          value={dataAddGeneralMenu.menu_name}
        />
         </Grid>
       </Grid>
       
       <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center",mt:2}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Menu Route')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
      <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email_address"
          size="small"
          onChange={(e) => {
                setdataAddGeneralMenu({...dataAddGeneralMenu,menu_route:e.target.value});
          
           
          }}
          value={dataAddGeneralMenu.menu_route}
        />
         </Grid>
       </Grid>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseaddGeneralMenu}> {t("Cancel")} </Button>
    <Button type="submit" autoFocus>
    {t("Save")} 
    </Button>
  </DialogActions>
  </form>
</Dialog>
    );
};

const showDialogEditGeneralMenu = () => {
   
    return (
      <Dialog
      disableScrollLock
      fullWidth
      open={openeditGeneralMenu}
      onClose={handleCloseeditGeneralMenu}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <form onSubmit={handleEditGeneralMenuSubmit}>
      <DialogTitle id="alert-dialog-title">
      {t("Edit Menu")} 
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        </DialogContentText>
        <Grid container  >
                        <Grid 
                        sx={{alignSelf:"center"}}
                        item xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}>
        <Typography  variant="inherit"  component="h6">
    {t('Menu Name')} :
    </Typography>
    </Grid>
    
    <Grid item xs={12}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email_address"
              size="small"
              onChange={(e) => {
                    setdataEditGeneralMenu({...dataEditGeneralMenu,menu_name:e.target.value});
              
               
              }}
              value={dataEditGeneralMenu.menu_name}
            />
             </Grid>
             </Grid>

             <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center",mt:2}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Menu Route')} :
</Typography>
</Grid>
             <Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
       <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email_address"
          size="small"
          onChange={(e) => {
                setdataEditGeneralMenu({...dataEditGeneralMenu,menu_route:e.target.value});
          
           
          }}
          value={dataEditGeneralMenu.menu_route}
        />
         </Grid>
           </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseeditGeneralMenu}> {t("Cancel")} </Button>
        <Button type="submit" autoFocus>
        {t("Save")} 
        </Button>
      </DialogActions>
      </form>
    </Dialog>
        );
    };

  return (
    <Container fixed sx={{ mt: 3}} >
      <Box 
      sx={{}}
     >
          {/* Summary Icons */}
          <Grid 
          container item
          justifyContent="center"
          alignItems="center"
          sx={{
              margin: 'auto',
              width: '100%',
              maxWidth: '60rem'
          }}
          ><Typography variant="body1">{t(`Menu Management Page`)}</Typography>

          </Grid>

          <Grid container justifyContent="flex-end">
              <Button 
                  size="medium"
                  variant="contained" 
                  sx={{
                      marginTop: 2,
                      marginLeft:2,
                      marginRight: 2,
                      minWidth: 200,
                      backgroundColor: "#c79059"
                  }}
                  className="requestButton"
                  onClick={() => { handleAddGeneralMenu()}}
              >
                  {t("Add Menu")} 
              </Button>
          </Grid>
          <br />
          <Grid>
          <Grid container spacing={2}>
 
  <Grid item xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}>
   <TextField 
   fullWidth 
                  placeholder="ค้นหา..."
                  variant="outlined"
                  size="small"
                  InputProps={{
                      endAdornment: (<IconButton><SearchIcon /></IconButton>)
                  }}
                  onChange={(e)=>{
                      requestSearch(e.target.value)
                      setSearchText(e.target.value)
                  }} 
                  sx={{
                      //  width:500,
                      "& .MuiInput-underline:before": {
                          borderBottom: 1,
                          borderColor: "divider",
                      },
                      marginBottom:2
                  }}
              />
  </Grid>
  <Grid item xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}>
  </Grid>
</Grid>
              
              <DataGrid
                  // components={{ Toolbar: CustomToolbar }}
                  sx={{ backgroundColor: "white"}}  
                  rows={tableData}
                  columns={requestColumns}
                  // componentsProps={{
                  //     toolbar: {
                  //       value: searchText,
                  //       onChange: (searchVal: string) => requestSearch(searchVal),
                  //       onCancelSearch: () => cancelSearch()
                  //     }
                  // }}
                  autoHeight
                  pageSize={10}
                  rowsPerPageOptions={[10]}
              />
          </Grid>
          <br />
          <br />
          {showDialog()}
          {showDialogAddGeneralMenu()}
          {showDialogEditGeneralMenu()}
      </Box>
      </Container>
);
                }
export default GeneralMenu;
