import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { imageUrl } from "../../../../services/Constants";
import * as rolesActions from "../../../../actions/roles.action";
import * as requestDeleteActions from "../../../../actions/request.delete.actions";
import * as menuconfigActions from "../../../../actions/menuconfig.action";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../../reducers";
import {
    Stack,
    IconButton,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    CardContent,
    Card,
    TextField
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../..";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { server, TOKEN } from "../../../../services/Constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue, red } from "@mui/material/colors";
import "./RolesManagement.css";
import { Search as SearchIcon } from "@mui/icons-material";
import Container from '@mui/material/Container';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu";
import * as loginActions from "../../../../actions/login.action";
// import SearchBar from "material-ui-search-bar";
// const CustomToolbar = (props:any) => {
//     return (
//         <Box>
//             <Grid>
//                 <SearchBar {...props} />
//             </Grid>
//         </Box>
//     )
// }



interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}


type RolesManagementProps = {
  //
};

const RolesManagement: React.FC<any> = () => {
  const RolesStateReducer = useSelector((state: RootReducers) => state.rolesStateReducer);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedRoles, setSelectedRoles] = React.useState<any>({});
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dataAddRoles, setdataAddRoles] = React.useState({group:"",lang:"en"});
  const [dataEditRoles, setdataEditRoles] = React.useState({id:0,group:"",lang:""});
  
  const [openaddRolesgroup, setOpenaddRolesgroup] = React.useState(false);
  const [openeditRolesgroup, setOpeneditRolesgroup] = React.useState(false);

  const Alllanguages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'Thai'
    }
  ];

  const handleAddRolesGroup = () => {
    setdataAddRoles({group:"",lang:"en"})
    console.log("add");
    setOpenaddRolesgroup(true);
};

const handleEditRolesGroup = (data:any) => {
    setdataEditRoles({id:data.id,group:data.group,lang:data.lang});
    console.log("edit");
    setOpeneditRolesgroup(true);
};


  const handleCloseaddRolesgroup = () => {
    setOpenaddRolesgroup(false);
  };

  const handleCloseeditRolesgroup = () => {
    setOpeneditRolesgroup(false);
  };

 
 

  React.useEffect(() => {
    if (RolesStateReducer.isAdddata) {
        Swal.fire({
          title: `${t("Add Data")}`,
          text: `${t("Add Data Success")}`,
            icon: "success",
          });
          setOpenaddRolesgroup(false);
          dispatch(rolesActions.setROLESFetchingToState());
          dispatch(rolesActions.loadROLES());
      return;
    } else if(RolesStateReducer.isAddError) {
        Swal.fire({
          title: `${t("Add Data")}`,
          text: `${t("Add Data Failed")}`,
            icon: "error",
          });
          setOpenaddRolesgroup(false);
        dispatch(rolesActions.setROLESFetchingToState());
        dispatch(rolesActions.loadROLES());
     
    }else if(RolesStateReducer.isEditdata) {
        Swal.fire({
          title: `${t("Edit Data")}`,
          text: `${t("Edit Data Success")}`,
            icon: "success",
          });
          setOpeneditRolesgroup(false);
        dispatch(rolesActions.setROLESFetchingToState());
        dispatch(rolesActions.loadROLES());
     
    }else if(RolesStateReducer.isEditError) {
        Swal.fire({
          title: `${t("Edit Data")}`,
          text: `${t("Edit Data Failed")}`,
            icon: "error",
          });
          setOpeneditRolesgroup(false);
        dispatch(rolesActions.setROLESFetchingToState());
        dispatch(rolesActions.loadROLES());
     
    }
    
    else if(RolesStateReducer.isDeletedata) {
        Swal.fire({
          title: `${t("Delete Data")}`,
            text: `${t("Delete Data Success")}`,
            icon: "success",
          });
        dispatch(rolesActions.setROLESFetchingToState());
        dispatch(rolesActions.loadROLES());
     
    }else if(RolesStateReducer.isDeleteError) {
        Swal.fire({
          title: `${t("Delete Data")}`,
          text: `${t("Delete Data Failed")}`,
            icon: "error",
          });
        dispatch(rolesActions.setROLESFetchingToState());
        dispatch(rolesActions.loadROLES());
     
    }else {
    }
  }, [RolesStateReducer]);
  


  //load data table
  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      // navigate("/login");
      dispatch(loginActions.logouttologinpage(navigate));
    }
    dispatch(menuconfigActions.getMENU_CONFIGCheckRouteConfigmenu(window.location.pathname.replace("/", "").split("/")[0],navigate));
      dispatch(rolesActions.loadROLES());

  }, []);
  React.useEffect(() => {

  }, [RolesStateReducer]);

  const requestColumns: GridColDef[] = [
      {
          headerName: `${t('ID')}`,
          field: "id",
          maxWidth: 80,
          minWidth: 50
      },
      {
          headerName: `${t("Roles")}`,
          field: "role",
          flex: 1,
          minWidth: 200
      },
      
      {
          headerName: "",
          field: ".",
          maxWidth: 150,
          align: "center",
          renderCell: ({ row }: GridRenderCellParams<string>) => (
              <Stack direction="row">
                  <IconButton
                      aria-label="edit"
                      size="large"
                      onClick={() => {
                        navigate(`/general-roles-edit/${row.id}`);
                      }}
              >
                      <EditIcon 
                          fontSize="inherit" 
                          sx={{ color: blue[700] }}  
                      />
                  </IconButton>
                  <IconButton
                      disabled={row.status == "Close" || row.status == "Cancel" }
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                          // console.log(row)
                          setSelectedRoles(row);
                          setOpenDialog(true);
                      }}
                  >
                      <DeleteIcon 
                          fontSize="inherit" 
                          sx={{ color: red[500] }}
                      />
                  </IconButton>
              </Stack>
            ),
      },
  ];

  //search
  const [searchText, setSearchText] = React.useState("");
  const [searchText1, setSearchText1] = React.useState("");
  const [tableData, setTableData] = React.useState<any[]>([]);

  React.useEffect(() => {
      let  dataList:any[] = [];
      let index = 0;
      RolesStateReducer.result.forEach((item) =>{
          dataList[index] = item;
          index += 1;
      })
      setTableData(dataList)
  }, [RolesStateReducer.result]);

  const requestSearch = (searchValue: string) => {
      // const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
      // const filteredRows = RolesStateReducer.result.filter((o: any) => {
      //   return Object.keys(o).some((k: any) => {
      //     return searchRegex.test(o[k].toString());
      //   });
      // });
      const filteredRows = RolesStateReducer.result.filter((row) => {
        return row.role?.toString().toLowerCase().includes(searchValue.toLowerCase());
      });
      setTableData(filteredRows);
  };

  // const cancelSearch = () => {
  //     setSearchText("");
  //     requestSearch(searchText);
  // };
  const handleDeleteConfirm = (ROLES_id:number) => {
      dispatch(rolesActions.deleteRoles(ROLES_id,navigate));
      setOpenDialog(false);
  };



  const showDialog = () => {
      if (selectedRoles === null) {
          return "";
      }
  return (
      <Dialog
      fullWidth
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle id="alert-dialog-slide-title">
            {t("Delete Data")}
             
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">  {t("Confirm Delete Data")} : {selectedRoles.role}</DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="info">
              {t(`Cancel`)}
          </Button>
          <Button onClick={() => handleDeleteConfirm(selectedRoles.id)} color="primary">
              {t(`Save`)}
          </Button>
          </DialogActions>
      </Dialog>
      );
  };

 


  return (
    <Container fixed sx={{ mt: 3}} >
      <Box 
      sx={{}}
     >
          {/* Summary Icons */}
          <Grid 
          container item
          justifyContent="center"
          alignItems="center"
          sx={{
              margin: 'auto',
              width: '100%',
              maxWidth: '60rem'
          }}
          ><Typography variant="body1">{t(`Roles Management Page`)}</Typography>

          </Grid>

          <Grid container justifyContent="flex-end">
              <Button 
                  size="medium"
                  variant="contained" 
                  sx={{
                      marginTop: 2,
                      marginLeft:2,
                      marginRight: 2,
                      minWidth: 200,
                      backgroundColor: "#c79059"
                  }}
                  className="requestButton"
                  // onClick={() => { handleAddRolesGroup()}}
                  onClick={() => {
                    navigate("/general-roles-add");
                  }}
              >
                  {t("Add Roles Group")} 
              </Button>
          </Grid>
          <br />
          <Grid>
          <Grid container spacing={2}>
 
  <Grid item xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}>
   <TextField 
   fullWidth 
                  placeholder="ค้นหา..."
                  variant="outlined"
                  size="small"
                  InputProps={{
                      endAdornment: (<IconButton><SearchIcon /></IconButton>)
                  }}
                  onChange={(e)=>{
                      requestSearch(e.target.value)
                      setSearchText(e.target.value)
                  }} 
                  sx={{
                      //  width:500,
                      "& .MuiInput-underline:before": {
                          borderBottom: 1,
                          borderColor: "divider",
                      },
                      marginBottom:2
                  }}
              />
  </Grid>
  <Grid item xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}>
  </Grid>
</Grid>
              
              <DataGrid
                  // components={{ Toolbar: CustomToolbar }}
                  sx={{ backgroundColor: "white"}}  
                  rows={tableData}
                  columns={requestColumns}
                  // componentsProps={{
                  //     toolbar: {
                  //       value: searchText,
                  //       onChange: (searchVal: string) => requestSearch(searchVal),
                  //       onCancelSearch: () => cancelSearch()
                  //     }
                  // }}
                  autoHeight
                  pageSize={10}
                  rowsPerPageOptions={[10]}
              />
          </Grid>
          <br />
          <br />
          {showDialog()}
      </Box>
      </Container>
);
                }
export default RolesManagement;
