import * as React from "react";
import "./FAQPage.css";
import { Paper, Grid } from "@mui/material";
import Iframe from "react-iframe";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import getLanguage from "../../Translation/Translation";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
  IconButton,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import Search from "@mui/icons-material/Search";
import { Cookie, Search as SearchIcon } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs, { Dayjs } from "dayjs";
import DatePicker from "@mui/lab/DatePicker";
import { Formik, FormikProps } from "formik";
import { ContactUs } from "../../../types/contactus.type";
import { useAppDispatch } from "../../..";
import * as contactusActions from "../../../actions/contactus.action";
import { useNavigate } from "react-router-dom";

type FAQPageProps = {
  //
};

// export const languages = [
//   {
//     code: 'en',
//     name: 'English'
//   },
//   {
//     code: 'th',
//     name: 'Thai'
//   }
// ];

const gridStyles = {
  backgroundColor: "gray",
  marginLeft: "auto",
  marginRight: "auto",
  paddingRight: 1,
  paddingBottom: 1,
};

const Contents = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center",
}));

const FAQPage: React.FC<any> = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showlanguage, setShowlanguage] = React.useState(`${i18n.language}`);
  const [searchText, setSearchText] = React.useState("");
  const [searchrows, setsearchrows] = React.useState<any[]>([]);
  const [rows, setrows] = React.useState<any[]>([]);
  const [searched, setSearched] = React.useState<string>("");
  const [showcloseicon, setshowcloseicon] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const setdataourfirmfaq = [
    { showgroup: true, group: "Our Firm FAQ" },
    {
      group: "Our Firm FAQ",
      name: "What type of legal service does your firm provide?",
      detail: "Dej-Udom & Associates is a full service commercial law firm, handling all types of business and commercially related legal matters. Over 80% of our clients are foreign companies or multinational firms.",
    },
    {
      group: "Our Firm FAQ",
      name: "How big is your firm and do you have lawyers that understand international law?",
      detail: "With over 35 lawyers, we are one of the largest law firms in Thailand.  All of our senior Thai lawyers speak English fluently and have extensive experience in international law. Our legal staff includes British, New Zealand and American consultants who can provide special assistance for foreign clients.",
    },
    {
      group: "Our Firm FAQ",
      name: "How does your service compare in cost with international firms?",
      detail: "We offer very competitive rates for all types of standard legal service including setting up companies and corporate secretarial work. Our experience is comparable to large international law firms, yet as a local firm, we are able to offer our clients good value for their money.",
    },
    {
      group: "Our Firm FAQ",
      name: "Is there any type of legal service that Dej-Udom & Associates cannot handle?",
      detail: "Through our membership in the  International Lawyers Network, an affiliation of lawyers from around the world, and our association with Kelly, Drye and Warren LLP, a leading US law firm we can assist with legal matters in most countries of the world.",
    },
    {
      group: "Our Firm FAQ",
      name: "Can you help if we are starting a business in Thailand?",
      detail: "We can assist and advise on every aspect of business start-ups in Thailand, including Board of Investment promotion, if applicable.  We provide solid tax advice, which is one of our strong areas of expertise.  Being a local firm, we can also warn clients of pitfalls and mistakes that they should avoid.",
    },
    {
      group: "Our Firm FAQ",
      name: "What about work permits and visas?",
      detail:"As part of setting up a company, we can advise on your best strategy for obtaining work permits, including under the Board of Investment, if applicable. Our firm regularly handles the applications and renewals for hundreds of work permits and visas on a routine basis."
    },
    {
      group: "Our Firm FAQ",
      name: "Do you have any experience in IP and IT areas?",
      detail:"We have a large Intellectual Property department serving many international clients. Additionally we have always considered Information Technology one of our strongest areas of expertise, as we have many clients in the IT area. We are experienced in computer service contracting and licensing issues in this field."
    },
    {
      group: "Our Firm FAQ",
      name: "Can you help if I need to go to court?",
      detail:"Dej-Udom & Associates has its own Litigation Department, which has successfully resolved cases at all three levels of the Thai court system.  We can also advise and assist with negotiations and dispute resolution, and have experience in debt recovery and arbitration."
    },
  ];

  const setdatafaqimmigration = [
    { showgroup: true, group: "FAQ - Immigration" },
    {
      group: "FAQ - Immigration",
      name: "1) How do I start the process of applying to work in Thailand?",
      detail:"In order to obtain a work permit you must first apply for a non-immigrant “B” visa at the Thai embassy or consulate in the country of your nationality or where you are resident. To apply, you will need to have a valid passport, a letter of sponsorship from the intended employer in Thailand, four passport photos and a completed application form."
    },
    {
      group: "FAQ - Immigration",
      name: "2) What about family members, who will come with me to Thailand?",
      detail:"When you apply for your non-immigrant “B” visa, you can also apply for a non-immigrant “O” visa for your dependants. Your legal spouse, children under the age of 20, and your parents (not the parents of your spouse) will be accepted under this category. They will also need to have valid passports."
    },
    {
      group: "FAQ - Immigration",
      name: "3) How long does it take to get a Thai visa?",
      detail:"The process usually takes two working days."
    },
    {
      group: "FAQ - Immigration",
      name: "4) Can I work in Thailand once I have a non-immigrant “B” visa?",
      detail:"No, you are not permitted to work in Thailand just because you have obtained a non-immigrant “B” visa. This is just the first step. You must apply for a work permit in Thailand in order to work."
    },
    {
      group: "FAQ - Immigration",
      name: "5) How do I get my work permit?",
      detail:"You must obtain your work permit by applying in Thailand. Generally the work permit application process will take approximately 3 to 4 weeks. You will need to provide in advance a required list of documents including: copies of university level degrees/or equivalent education, a letter of assignment stating job position in Thailand and resume listing all previous employment by other companies. For dependants, you will need to supply their birth certificates and in the case of a spouse a wedding license."
    },
    {
      group: "FAQ - Immigration",
      name: "6) How long does it take to get a work permit?",
      detail:"Once you have supplied all the required documents, if your application is made under the regular process it will take 3 to 4 weeks. However, if your intended employer in Thailand, qualifies for you to apply for your work permit through the One Stop Service Center, the process will only take 1 day. The one Stop Service Process can be made only after you are in Thailand and are holding a non-immigration visa."
    },
    {
      group: "FAQ - Immigration",
      name: "7) Can I work in Thailand before I get my work permit?",
      detail:"Even though you have entered Thailand on a non-immigrant “B” visa, you are not permitted to work until you have received your work permit. If the authorities find that you are working without a work permit, you risk serious penalties."
    },
    {
      group: "FAQ - Immigration",
      name: "8) How long can I stay in Thailand with my visa?",
      detail:"When you enter Thailand with a non-immigrant “B” visa you will be granted a 90-stay. Once you have received your work permit, you can apply to extend your visa. In most cases your visa extension will be for one year, or in some cases longer. As long as you hold a valid work permit, you and your dependants will be able to stay in Thailand."
    },
    {
      group: "FAQ - Immigration",
      name: "9) Will I have any problems traveling in and out of Thailand with my visa?",
      detail:"Every time you enter Thailand you must make sure that you have a valid re-entry permit attached to your visa. You can request multiple re-entries when you apply for your visa. If you have your visa extended in Thailand, you will also need to apply for multiple re-entries if you intend to travel in and out of the country."
    },
  ];
  const setdatafaqlitigation = [
    { showgroup: true, group: "FAQ - Litigation" },
    {
      group: "FAQ - Litigation",
      name: "1) When does a matter go to court in Thailand?",
      detail:"When parties fail to settle disputes through negotiations, unless contracts include provisions for arbitration, the matter generally is taken to court for resolution. In Thailand due to social and cultural issues, if relations between two parties have deteriorated to the stage where court action is threatened or initiated, it is unlikely that a satisfactory compromise will be reached."
    },
    {
      group: "FAQ - Litigation",
      name: "2) How does the court system work?",
      detail:`Cases, depending on the dispute or charges, are either brought before the Civil Court or the Criminal Court. There are three different levels of these courts: \n
      1) Court of First Instance; \n
      2) Court of Appeal (U-thorn Court); and \n
      3) Supreme Court (Dika).`
    },
    {
      group: "FAQ - Litigation",
      name: "3) Are there other types of courts?",
      detail:"There are specific courts for certain types of cases: Labor Court, Family and Juvenile Court, Intellectual Property and International Trade Court, IP & IT Court and Tax Court. Generally once cases appear before these special courts, they proceed more quickly than through the normal court system. However, depending on the specific caseload, there could still be a delay before the first hearing. All of these courts have only two levels of appeal."
    },
    {
      group: "FAQ - Litigation",
      name: "4) Is there a trial by jury? ",
      detail:"Trial by jury does not exist in Thailand. All questions of law and fact are determined before a judge(s) who will render a decision. The number of judges who hear a case varies from one to three, depending on the court and the level of the appeal."
    },
    {
      group: "FAQ - Litigation",
      name: "5) Can foreigners file a court case in Thailand?",
      detail:"Yes, foreigners have the right to file cases in Thailand in any of the courts, if the matter they wish to pursue in the courts falls under Thai jurisdiction. A person does not have to be a resident of Thailand to file a case."
    },
    {
      group: "FAQ - Litigation",
      name: "6) What language is used in court?",
      detail:"All proceedings are conducted in Thai, so documents must be translated before they are admitted in court except for the IP & IT Court when the parties agree not to translate  the documents."
    },
    {
      group: "FAQ - Litigation",
      name: "7) How long will a case take?",
      detail:"The length of a case depends on its complexity. Generally, cases appear before the court no more than once a month for a half or full day hearing. Thus, if a case is complicated, lawyers and witnesses must return month after month to complete the testimony. A party may appear at court on the appointed date, only to discover that the other party has asked for a postponement. In this event, the case will be rescheduled for next month, or even later. Consequently, it can take between one-and-a-half to four years to obtain a decision from the court in the court of first instance, depending on the complexity of the case."
    },
    {
      group: "FAQ - Litigation",
      name: "8) Is there an appeal process?",
      detail:"Should one of the parties in a case disagree with the decision of the Court of First Instance, that party can file a written appeal to the Court of Appeal within one month from the date of delivery of judgment of the Court of First Instance. The Court of Appeal hears civil and criminal cases, brought from all parts of the country, and it either reaffirms or revises the decision of the lower court."
    },
    {
      group: "FAQ - Litigation",
      name: "9) What is the appeal process?",
      detail:"There is no taking of evidence from witnesses at the level of the Appeal Court or at the Dika (Supreme) Court. Unless decided otherwise by the court, only written arguments submitted by lawyers representing the parties, and the related files from the Court of First Instance, will be deliberated by the justices of the Court of Appeal and the Supreme Court."
    },
  ];
  const setdatafaqintellectual = [
    { showgroup: true, group: "FAQ - Intellectual Property" },
    {
      group: "FAQ - Intellectual Property",
      name: "1. What are your services related to trademarks?",
      detail:"We conduct trademark searches and provide advice on the possibility of registration of the proposed mark.  We further assist with the filing process by handling all aspects of registration and renewal of trademarks.  Our services include filing oppositions, counter-statements, appeals and cancellation petitions for trademark, servicemarks, house mark and certification marks. In addition, we also file recordings of licenses and assignments."
    },
    {
      group: "FAQ - Intellectual Property",
      name: "2. What are your services related to patents?",
      detail:"Our work starts with conducting the patent search of the published applications.  We further file and process the application through all stages up to the granting of the patent and payment of the annuity fees. Patents include invention patent, design patent and petty patent.  Recording of licensing and assignment are also provided."
    },
    {
      group: "FAQ - Intellectual Property",
      name: "3. What are your services related to copyrights?",
      detail:"Even though registration of copyright works is not compulsory in Thailand, the copyright owner may file a recordal of the copyrighted work with the Department of Intellectual Property to serve as a database for any interested party to contact the copyright owner for licensing of the copyrighted work."
    },
    {
      group: "FAQ - Intellectual Property",
      name: "4. What are your services related to IP litigation?",
      detail:"Our Intellectual Property Department lawyers work closely with our Litigation Department lawyers to provide opinions and strategies to our clients when they face problems concerning passing off and counterfeiting infringements."
    },
    {
      group: "FAQ - Intellectual Property",
      name: "5. What are your services related to IP commercialization?",
      detail:"We arrange meetings with our clients to discuss their business plan not only for protection of their intellectual property rights but also to assist with the establishment of a new company or business.  We also have extensive experience concerning taxation matters and can assist with all tax planning."
    },
  ];
  let AlldataAgreement: any = [];
  

  React.useEffect(() => {
    AlldataAgreement = [...setdataourfirmfaq,...setdatafaqimmigration,...setdatafaqlitigation,...setdatafaqintellectual];
    setrows(AlldataAgreement);
    setsearchrows(AlldataAgreement);
  }, []);
  const classes: SxProps<Theme> | any = {
    root: { display: "flex", justifyContent: "left" },
    buttons: { marginTop: 2 },
  };

  const handleChange = (event: SelectChangeEvent) => {
    setShowlanguage(event.target.value as string);
  };
  const onLanguageClick = (code: any) => {
    setShowlanguage(code);
    i18n.changeLanguage(code);
    // i18next.changeLanguage(code);
  };
  const languages = [
    {
      code: "en",
      name: "English",
    },
    {
      code: "th",
      name: "ไทย",
    },
  ];
  // function onLanguageClick(code){
  //   i18next.changeLanguage(code);
  // }

  function NewlineText(props:any) {
    const text = props.text;
    return text.split('\n').map((str: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined) => <p>{str}</p>);
  }

  const requestSearch = (searchValue: string) => {
    setSearched(searchValue);
    const filteredRows = rows.filter((row) => {
      // console.log(row)
      return row.name
        ?.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    });
    let calldatagroup = "";
    let count = 0;
    const resultArray = filteredRows.reduce((dataAll, databyRow, dataIndex) => {
      dataAll.push(databyRow);
      if (dataIndex == 0) {
        dataAll.splice(dataIndex, 0, {
          showgroup: true,
          group: databyRow.group,
        });
        count++;
        calldatagroup = databyRow.group;
      } else if (calldatagroup !== databyRow.group) {
        dataAll.splice(dataIndex + count, 0, {
          showgroup: true,
          group: databyRow.group,
        });
        count++;
        calldatagroup = databyRow.group;
      }
      return dataAll;
    }, []);
    setsearchrows(resultArray);
    if (searchValue === "") {
      setshowcloseicon(false);
    } else {
      setshowcloseicon(true);
    }
    setPage(0);
};

const handleClickClearSearch = () => {
  setSearched("");
  setsearchrows(rows);
  setshowcloseicon(false);
};

  const initialValues: ContactUs = {
    name: "",
    email_address: "",
    contact_number: "",
    message: "",
  };
  return (
    <Container fixed sx={{ mt: 3, padding: "2rem 0" }}>
        <Grid container alignItems="center"  justifyContent="center">
      <Grid item className="contact-row" xs={10}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8} 
                    // sx={{ mx: 15 }}
                    >
        <Grid className="contact-col">
        <Grid container alignItems="center"  justifyContent="center">
        <Grid item 
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                >
                  <TextField
                 fullWidth
                 placeholder="Search"
                 variant="outlined"
                 size="small"
                 value={searched}
                 onChange={(e)=>{
                     requestSearch(e.target.value)
                    //  setSearchText(e.target.value)
                 }} 
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickClearSearch}
                        edge="end"
                      >
                        {showcloseicon ? <CloseIcon /> : ""}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
                </Grid>
                </Grid>
                <Grid className="contact-col" sx={{height:"60vh", overflow:"auto",marginTop: "3rem"}}>
                {React.Children.toArray(
  searchrows.map((value, index) => (   
    <>
{value.showgroup == true ? (
   <>
                        <Typography
            sx={{ color: "#4F4A41", marginBottom: "2rem", textAlign: "left" }}
            variant="h4"
            component="h4"
          >
            {value.group}
          </Typography>
                        </>
            ) : (
              <>
<Typography
            sx={{ color: "#4F4A41", marginBottom: "0rem", textAlign: "left",textDecoration: "underline" }}
            variant="h6"
            component="h6"
          >
            {value.name}
          </Typography>
          <Typography
            sx={{ color: "#4F4A41", marginBottom: "1rem", textAlign: "left" }}
          >
             <NewlineText text={value.detail} />
             {/* {value.detail} */}
          </Typography>
          </>
              )}
</>
  ))

)}
</Grid>
         

        </Grid>
      </Grid>
      </Grid>
      
    </Container>
  );
};

export default FAQPage;
