import * as React from "react";
import "./HomePage.css";
import { Paper, Grid } from "@mui/material";
import Iframe from "react-iframe";
import i18next from 'i18next';
import Button, { ButtonProps } from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import getLanguage from '../../Translation/Translation';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Container from '@mui/material/Container';
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "../../..";
import * as registerActions from "../../../actions/register.action";
import * as verifyEmailActions from "../../../actions/verifyemail.action";
import { RootReducers } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import Typography from "@mui/material/Typography";
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PrintIcon from '@mui/icons-material/Print';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { fontSize } from "@mui/system";
import CardMedia from '@mui/material/CardMedia'
import { highlightTrailingWhitespace } from "jest-matcher-utils";
import SME_Mate_ENG from "../../layouts/assets/SME_Mate_ENG.mp4";
import {  useNavigate, Link } from "react-router-dom";
import sub1 from "../../../components/layouts/assets/subh3.png";
import sub2 from "../../../components/layouts/assets/subh2.png";
import sub3 from "../../../components/layouts/assets/subh1.png";
import about1 from "../../../components/layouts/assets/home1.png";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

type HomePageProps = {
  //
};



// export const languages = [
//   {
//     code: 'en',
//     name: 'English'
//   },
//   {
//     code: 'th',
//     name: 'Thai'
//   }
// ];

const gridStyles = {
  backgroundColor: "blanchedalmond",
  width: 1,
  paddingBottom: 1,
};

const Contents = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center"
}));

const HomePage: React.FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [showlanguage, setShowlanguage] = React.useState(`${i18n.language}`);
  const registerReducer = useSelector((state: RootReducers) => state.registerReducer);

  React.useEffect(() => {
    dispatch(verifyEmailActions.setVerifyEmailFetchingToState());
    if(registerReducer.result){
      Swal.fire({
        title: 'สมัครสมาชิก !!',
        text: 'สมัครสมาชิก สำเร็จ !!',
        icon: 'success',
      })
      // dispatch(registerActions.setRegisterFetchingToState());
      return
    }
  }, []);


  const handleChange = (event: SelectChangeEvent) => {
    setShowlanguage(event.target.value as string);
  };
  const onLanguageClick = (code: any) => {
    setShowlanguage(code);
    i18n.changeLanguage(code);
  };
  const languages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'ไทย'
    }
  ];
 

  return (
    <Grid>
      <Grid>
        <Container fixed maxWidth="lg" className="about-container">
          <Grid
            container
            justifyContent="start"
            className="why-container"
          >
            <Grid  xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="about-text-heading-title" >
                {t(`SME MATE: Your Business Growth Partner`)}
              </Typography>
              <Typography className="about-text-descritons" >
                {t("Empower your small and medium enterprise with expert knowledge and support to thrive in today's competitive landscape.")}
              </Typography>
              <Grid spacing={4}>
                
                <Button
                    onClick={() => navigate("/register")}
                    size="medium"
                    sx={{   
                      paddingLeft:"10px",
                      paddingRight:"10px",
                      paddingTop:"10px",
                      paddingBottom:"10px",
                      marginBottom: "20px",
                      marginRight: "10px",
                      color: "black",
                      backgroundColor: "#CA965C",
                      "&:hover": {
                        backgroundColor: "#F2903A",
                      },
                    }}
                  >
                    <Typography sx={{fontSize: "18px",fontWeight: "700", fontFamily: "Arial",color: "#4F4A41",width: "180px"}} >{t("Register Now")}</Typography>
                  </Button>
                <Button
                    onClick={() => navigate("/about-us")}
                    size="medium"
                    variant="outlined"
                    sx={{   
                      marginBottom: "20px",
                      paddingLeft:"10px",
                      paddingRight:"10px",
                      paddingTop:"10px",
                      paddingBottom:"10px",
                      color: "#F2903A",
                      backgroundColor: "#ffffff",
                      borderColor: "#F2903A",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                        color: "#CA963c",
                        borderColor: "#CA963c",
                      },
                    }}
                  >
                    <Typography sx={{fontSize: "18px",fontWeight: "700", fontFamily: "Arial", width: "180px"}} >{t("Learn More")}</Typography>
                  </Button>
                  {/* <ButtonLogin
                    onClick={() => navigate("/login")}
                    size="medium"
                    variant="outlined"
                    sx={{ display: { marginLeft: "0.5rem" } }}
                  >
                    {t("Login")}
                  </ButtonLogin> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            className="why-container"
            sx={{paddingTop: "80px"}}
          >
            <Grid  className="col-grap-home" xs={12} sm={12} md={4} lg={4} xl={4}>
                <img src={sub1} className="img-sub" />
                <Typography className="about-text-sub-heading" >
                  {t("DIY Business Calculations")}
                </Typography>
                <Typography className="about-text-descritons" >
                  {t("Leverage powerful tools to analyze your financials and make informed decisions that drive your business forward.")}
                </Typography>
            </Grid>
            <Grid className="col-grap-home" xs={12} sm={12} md={4} lg={4} xl={4}>
                <img src={sub2} className="img-sub" />
                <Typography className="about-text-sub-heading" >
                  {t("Document Request System")}
                </Typography>
                <Typography className="about-text-descritons" >
                  {t("Streamline your paperwork with our intuitive ticketing system, keeping your documents organized and accessible.")}
                </Typography>
            </Grid>
            <Grid className="col-grap-home" xs={12} sm={12} md={4} lg={4} xl={4}>
              <img src={sub3} className="img-sub" />

                <Typography className="about-text-sub-heading" >
                  {t("Download and Print")}
                </Typography>
                <Typography className="about-text-descritons" >
                {t("Conveniently access and print essential business documents whenever you need them, right at your fingertips.")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            className="why-container"
          >
            <Grid className='container-text' xs={12} sm={12} md={7} lg={7} xl={7} sx={{marginBottom: "50px"}}>
              <Grid>
                <Typography className="about-text-heading-home" >
                  {t(`Exclusive Support for Your Success`)}
                </Typography>
              </Grid>
              <Grid>
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot sx={{backgroundColor: '#CA965C' }}>
                        <Typography color="white" sx={{width: "20px", textAlign: "center"}} > 1 </Typography> 
                      </TimelineDot>
                      <TimelineConnector  sx={{ backgroundColor: '#CA965C' }} />
                    </TimelineSeparator>
                    <TimelineContent>
                            <Typography className="about-text-sub-home" >
                                {t("Expertise")}
                            </Typography>
                            <Typography className="about-text-descritons" >
                              {t("Receive personalized advice from seasoned business professionals to overcome challenges.")}
                            </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot sx={{backgroundColor: '#CA965C' }}> 
                        <Typography color="white" sx={{width: "20px", textAlign: "center"}}> 2 </Typography> 
                      </TimelineDot>
                      <TimelineConnector sx={{ backgroundColor: '#CA965C' }} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography className="about-text-sub-home" >
                          {t("Personalized Guidance for Your Success")}
                      </Typography>
                      <Typography className="about-text-descritons" >
                          {t("As your dedicated business partner, we provide personalized guidance and support to help you achieve your goals.")}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                    <TimelineDot sx={{backgroundColor: '#CA965C' }}> 
                      <Typography color="white" sx={{width: "20px", textAlign: "center"}}> 3 </Typography> 
                    </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography className="about-text-sub-home" >
                          {t("Document Request Portal")}
                      </Typography>
                      <Typography className="about-text-descritons" >
                          {t("Access our ticket system to efficiently request all your business documents 24/7.")}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </Grid>
            </Grid>
            <Grid className=' container-img' xs={12} sm={12} md={5} lg={5} xl={5} >
              <img src={about1} className="img-about" />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid sx={{backgroundColor : '#ffebcc', paddingBottom: "20px"}}>
        <Container fixed maxWidth="lg" className="about-container">
          <Grid
            container
            justifyContent="start"
            className="why-container"
            sx={{paddingTop: "80px"}}
          >
            <Grid className="col-grap-home" xs={12} sm={12}>
              <Card className="card-home">
                <CardContent>
                  <Grid sx={{marginBottom: "40px"}}>
                    <Typography className="about-text-heading-home" >
                      {t(`Our Commitment to SMEs`)}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      paddingLeft: "20px",
                      borderLeft: "4px solid #ffebcc", 
                    }}
                  >
                    <Typography className="about-text-descritons" >
                      <b>{t("We fill SME's heart with know-how and experience to move forward, challenging the outer world and beyond. ")}</b>{t("- Prof. Dej-Udom Krairit, CEO")}
                    </Typography>
                  </Grid>
                  <Grid container sx={{marginTop: "40px"}}>
                    <Grid  className="col-grap-home" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card className="card-home-sub">
                          <CardContent>
                            <Typography className="about-text-sub-heading" >
                              {t("Expertise")}
                            </Typography>
                            <Typography className="about-text-descritons" >
                              {t("Leverage our team's vast experience in various business domains.")}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid className="col-grap-home" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card className="card-home-sub">
                          <CardContent>
                            <Typography className="about-text-sub-heading" >
                              {t("Innovation")}
                            </Typography>
                            <Typography className="about-text-descritons" >
                              {t("Stay ahead with cutting-edge tools and strategies tailored for SMEs.")}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid className="col-grap-home" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card className="card-home-sub">
                          <CardContent>
                            <Typography className="about-text-sub-heading" >
                              {t("Support")}
                            </Typography>
                            <Typography className="about-text-descritons" >
                            {t("Benefit from our unwavering commitment to your business growth.")}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
    
  );
  

};


export default HomePage;
