import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { imageUrl } from "../../../../services/Constants";
import * as generalMenuActions from "../../../../actions/generalmenu.action";
import * as menuconfigActions from "../../../../actions/menuconfig.action";
import * as requestDeleteActions from "../../../../actions/request.delete.actions";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../../reducers";
import Autocomplete from '@mui/material/Autocomplete';
import * as requestTypeListActions from "../../../../actions/requesttype.list.action";
import {
    Stack,
    IconButton,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    CardContent,
    Card,
    TextField
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../..";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { server, TOKEN } from "../../../../services/Constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue, red } from "@mui/material/colors";
import "./RequestType.css";
import { Search as SearchIcon } from "@mui/icons-material";
import Container from '@mui/material/Container';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu";
import * as loginActions from "../../../../actions/login.action";
import * as userActions from "../../../../actions/user.action";
import i18next from 'i18next';

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}


type GeneralMenuProps = {
  //
};

const GeneralMenu: React.FC<any> = () => {
  const generalMenuStateReducer = useSelector((state: RootReducers) => state.generalMenuStateReducer);
  const menuconfigReducer = useSelector((state: RootReducers) => state.menuconfigStateReducer);
  const UserStateReducer = useSelector((state: RootReducers) => state.userStateReducer);
  const requesTypeListReducer = useSelector((state: RootReducers) => state.requesTypeListReducer);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedGeneralMenu, setSelectedGeneralMenu] = React.useState<any>({});
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [request_id_edit, setRequestTypeIdEdit] = React.useState(0);
  const [request_type_name, setRequestTypeName] = React.useState(String);
  const [request_type_name_edit, setRequestTypeNameEdit] = React.useState(String);
  const [request_type_name_en, setRequestTypeNameEng] = React.useState(String);
  const [request_type_name_en_edit, setRequestTypeNameEngEdit] = React.useState(String);
  const [checkedrequesttype, setcheckedrequesttype] = React.useState<any>([]);
  const [checkedrequesttypeedit, setcheckedrequesttypeEdit] = React.useState<any>([]);
  const [dataEditGeneralMenu, setdataEditGeneralMenu] = React.useState({id:0,menu_name:"",menu_route:""});
  const [openaddGeneralMenu, setOpenaddGeneralMenu] = React.useState(false);
  const [openeditGeneralMenu, setOpeneditGeneralMenu] = React.useState(false);
  const [user_list, setUserList] = React.useState<any[]>([]);
  const Alllanguages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'Thai'
    }
  ];

  const handleAddGeneralMenu = () => {
    setRequestTypeName("")
    setRequestTypeNameEng("")
    console.log("add");
    setOpenaddGeneralMenu(true);
};

const handleEditGeneralMenu = (data:any) => {
    // console.log(data)
    setRequestTypeIdEdit(Number(data.id))
    setRequestTypeNameEdit(data.request_type_name)
    setRequestTypeNameEngEdit(data.request_type_name_en)
    let user_id_list = [];
    user_id_list = data.user_id.split(',');
    let updateuserList: any = [];
    user_id_list.forEach((item:any,index:any) =>{
      user_list.forEach((items:any,indexs:any) =>{
        if(Number(item) == Number(items.id)){
          updateuserList.push(items)
        }
      })
    })
    // console.log(updateuserList)
    setcheckedrequesttypeEdit(updateuserList);
    setOpeneditGeneralMenu(true);
};


const handleCloseaddGeneralMenu = () => {
  setOpenaddGeneralMenu(false);
};

const handleCloseeditGeneralMenu = () => {
  setOpeneditGeneralMenu(false);
};

const handleAddGeneralMenuSubmit = (event:any) => {
    event.preventDefault();
    // console.log(request_type_name);
    // console.log(request_type_name_en);
    let dataAdd = {
      request_type_name:"",
      request_type_name_en:"",
      user_id:"",
    };
    dataAdd.request_type_name = request_type_name;
    dataAdd.request_type_name_en = request_type_name_en;
    if(checkedrequesttype.length == 0){
      Swal.fire({
        title: `${t("Check Data")}`,
        text: `${t("Please Enter the Reques Type Admin")}`,
        icon: "error",
      });
      return;
    }
    let user_admin = "";
    checkedrequesttype.forEach(async(item:any,index:number) => {
      if(index > 0){
        user_admin = user_admin + `,` + String(item.id);
      }else{
        user_admin = user_admin + String(item.id);
      }
      
    });
    dataAdd.user_id = user_admin;
    console.log(dataAdd);
    dispatch(requestTypeListActions.addRequestType(dataAdd,navigate));
};
const handleEditGeneralMenuSubmit = (event:any) => {
    event.preventDefault();
    let dataEdit = {
      request_type_name:"",
      request_type_name_en:"",
      user_id:"",
    };
    dataEdit.request_type_name = request_type_name_edit;
    dataEdit.request_type_name_en = request_type_name_en_edit;
    if(checkedrequesttypeedit.length == 0){
      Swal.fire({
        title: `${t("Check Data")}`,
        text: `${t("Please Enter the Reques Type Admin")}`,
        icon: "error",
      });
      return;
    }
    let user_admin = "";
    checkedrequesttypeedit.forEach(async(item:any,index:number) => {
      if(index > 0){
        user_admin = user_admin + `,` + String(item.id);
      }else{
        user_admin = user_admin + String(item.id);
      }
      
    });
    dataEdit.user_id = user_admin;
    // console.log(dataEdit);
    dispatch(requestTypeListActions.editRequestType(request_id_edit,dataEdit,navigate));
};

React.useEffect(() => {
    if (requesTypeListReducer.isAdd) {
        setOpenaddGeneralMenu(false);
        Swal.fire({
            title: `${t("Add Data")}`,
            text: `${t("Add Data Success")}`,
            icon: "success",
        });
          
        dispatch(requestTypeListActions.loadRequestTypeListData());
        return;
    } 
    if(requesTypeListReducer.isAddError) {
        setOpenaddGeneralMenu(false);
        Swal.fire({
            title: `${t("Add Data")}`,
            text: `${t("Add Data Failed")}`,
            icon: "error",
          });
        
        dispatch(requestTypeListActions.loadRequestTypeListData());
        return;
     
    }
    if(requesTypeListReducer.isEdit) {
        setOpeneditGeneralMenu(false);
        Swal.fire({
            title: `${t("Edit Data")}`,
            text: `${t("Edit Data Success")}`,
            icon: "success",
        });
        dispatch(requestTypeListActions.loadRequestTypeListData());
        return; 
    }
    if(requesTypeListReducer.isEditError) {
        setOpeneditGeneralMenu(false);
        Swal.fire({
            title: `${t("Edit Data")}`,
            text: `${t("Edit Data Failed")}`,
            icon: "error",
          });
          
          dispatch(requestTypeListActions.loadRequestTypeListData());
          return;
    }

    if(requesTypeListReducer.isDelete) {
        Swal.fire({
            title: `${t("Delete Data")}`,
            text: `${t("Delete Data Success")}`,
            icon: "success",
          });
          dispatch(requestTypeListActions.loadRequestTypeListData());
          return;
     
    }
    if(requesTypeListReducer.isDeleteError) {
        Swal.fire({
            title: `${t("Delete Data")}`,
            text: `${t("Delete Data Failed")}`,
            icon: "error",
          });
          dispatch(requestTypeListActions.loadRequestTypeListData());
          return;
    }
    // }
  }, [requesTypeListReducer]);
  
 

  //load data table
  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      dispatch(loginActions.logouttologinpage(navigate));
    }
    const callawaitfunction = async () =>{
      await dispatch(menuconfigActions.getMENU_CONFIGCheckRouteConfigmenu(window.location.pathname.replace("/", "").split("/")[0],navigate));
      await dispatch(requestTypeListActions.loadRequestTypeListData());
      await dispatch(userActions.loadUserByAdmin());
    }
    callawaitfunction();
      // menuconfigReducer
  }, []);

  React.useEffect(() => {
    if(UserStateReducer.result.length > 0){
        let  dataList:any[] = [];
        let index = 0;
        UserStateReducer.result.forEach((item) =>{
            dataList[index] = item;
            index += 1;
        })
        setUserList(dataList)
        console.log("dataList")
        console.log(dataList)
    }
  }, [UserStateReducer.result]);

  const requestColumns: GridColDef[] = [
      {
          headerName: `${t('ID')}`,
          field: "id",
          flex: 1,
          minWidth: 50 
      },
      {
          headerName: `${t("Request Type(TH)")}`,
          field: "request_type_name",
          flex: 1,
          minWidth: 150 
      },
      {
        headerName: `${t("Request Type(EN)")}`,
        field: "request_type_name_en",
        flex: 1,
        minWidth: 150 
      },
      {
        headerName: `${t("User Name")}`,
        field: "name",
        flex: 1,
        minWidth: 200,
        renderCell: (params) => {
          const valueArray = Array.isArray(params.value) ? params.value : [];
          return (
            <Grid>
              {
                valueArray.map((item: any, index: number) => (
                  <Grid key={index}>
                    {item}<br />
                  </Grid>
                ))
              }
            </Grid>
          );
        },
      },
      {
        headerName: `${t("Email")}`,
        field: "email",
        flex: 1,
        minWidth: 200,
        renderCell: (params) => {
          const valueArray = Array.isArray(params.value) ? params.value : [];
          return (
            <Grid>
              {
                valueArray.map((item: any, index: number) => (
                  <Grid key={index}>
                    {item}<br />
                  </Grid>
                ))
              }
            </Grid>
          );
        },
      },
      {
          headerName: "",
          field: ".",
          maxWidth: 150,
          align: "center",
          renderCell: ({ row }: GridRenderCellParams<string>) => (
              <Stack direction="row">
                  <IconButton
                      aria-label="edit"
                      size="large"
                      onClick={() => {
                        handleEditGeneralMenu(row);
                      }}
              >
                      <EditIcon 
                          fontSize="inherit" 
                          sx={{ color: blue[700] }}  
                      />
                  </IconButton>
                  <IconButton
                      disabled={row.status == "Close" || row.status == "Cancel" }
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                          // console.log(row)
                          setRequestTypeIdEdit(row.id);
                          setSelectedGeneralMenu(row)
                          setOpenDialog(true);
                      }}
                  >
                      <DeleteIcon 
                          fontSize="inherit" 
                          sx={{ color: red[500] }}
                      />
                  </IconButton>
              </Stack>
            ),
      },
  ];

  //search
  const [searchText, setSearchText] = React.useState("");
  const [searchText1, setSearchText1] = React.useState("");
  const [tableData, setTableData] = React.useState<any[]>([]);

  React.useEffect(() => {
    if(requesTypeListReducer.result.length >0){
      let  dataList:any[] = [];
      let index = 0;
      requesTypeListReducer.result.forEach((item) =>{
          dataList[index] = item;
          index += 1;
      })

      setTableData(dataList)
    }
  }, [requesTypeListReducer.result]);

  const requestSearch = (searchValue: string) => {
      // const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
      // const filteredRows = generalMenuStateReducer.result.filter((o: any) => {
      //   return Object.keys(o).some((k: any) => {
      //     return searchRegex.test(o[k].toString());
      //   });
      // });
      const filteredRows = generalMenuStateReducer.result.filter((row) => {
        return row.menu_name?.toString().toLowerCase().includes(searchValue.toLowerCase());
      });
      setTableData(filteredRows);
  };

  // const cancelSearch = () => {
  //     setSearchText("");
  //     requestSearch(searchText);
  // };
  const handleDeleteConfirm = () => {
      console.log(request_id_edit)
      setOpenDialog(false);
      dispatch(requestTypeListActions.deleteRequestType(request_id_edit,navigate));
      
  };



  const showDialog = () => {
  return (
      <Dialog
      fullWidth
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle id="alert-dialog-slide-title">
            {t("Delete Data")}
             
          </DialogTitle>
          <DialogContent>
          { i18next.language == "en" &&
            <DialogContentText id="alert-dialog-slide-description">  
              {t("Confirm Delete Data")} : {selectedGeneralMenu.request_type_name_en}
            </DialogContentText>
          }
          { i18next.language == "th" &&
            <DialogContentText id="alert-dialog-slide-description">  
              {t("Confirm Delete Data")} : {selectedGeneralMenu.request_type_name}
            </DialogContentText>
          }
          </DialogContent>
          <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="info">
              ยกเลิก
          </Button>
          <Button onClick={() => handleDeleteConfirm()} color="primary">
              ยืนยัน
          </Button>
          </DialogActions>
      </Dialog>
      );
  };

  const showDialogAddGeneralMenu = () => {
   
return (
  <Dialog
    disableScrollLock
    fullWidth
    open={openaddGeneralMenu}
    onClose={handleCloseaddGeneralMenu}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    sx={{
      zIndex: 0,
    }}
    
  >
  <form onSubmit={handleAddGeneralMenuSubmit}>
    <DialogTitle id="alert-dialog-title" >
      {t("Add Request Type")} 
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      </DialogContentText>
        <Grid container  >
          <Grid 
            sx={{alignSelf:"center"}}
            item xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Typography  variant="inherit"  component="h6">
              {t('Request Type(TH)')} :
            </Typography>
        </Grid>
        <Grid 
          item 
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email_address"
            size="small"
            onChange={(e) => {
              setRequestTypeName(e.target.value);
            }}
            // value={dataAddGeneralMenu.menu_name}
          />
          </Grid>
        </Grid>
        
        <Grid container  >
            <Grid 
              sx={{alignSelf:"center",mt:2}}
              item xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
            <Typography  variant="inherit"  component="h6">
              {t('Request Type(EN)')} :
            </Typography>
          </Grid>
          <Grid item xs={12}
            sm={8}
            md={8}
            lg={8}
            xl={8}
          >
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email_address"
                size="small"
                onChange={(e) => {
                  setRequestTypeNameEng(e.target.value);
                }}
                // value={dataAddGeneralMenu.menu_route}
              />
          </Grid>
        </Grid>
        <Grid container  >
            <Grid 
              sx={{alignSelf:"center",mt:2}}
              item xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
            <Typography  variant="inherit"  component="h6">
              {t('Reques Type Admin')} :
            </Typography>
          </Grid>
          <Grid 
            sx={{alignSelf:"center",mt:2}}
            item 
            xs={12}
            sm={8}
            md={8}
            lg={8}
            xl={8}
          >
              <Autocomplete
                  multiple
                  size="small"
                  fullWidth
                  limitTags={10}
                  id="multiple-limit-tags"
                  options={user_list || null}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                  defaultValue={[]}
                  onChange={(event, newValue:any) => {
                    setcheckedrequesttype(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                    variant="outlined"
                    {...params} label={t("Select")}  />
                  )}
                  sx={{
                    //  width:500,
                    "& .MuiInput-underline:before": {
                      borderBottom: 1,
                      borderColor: "divider",
                    },
                  }}
              />
          </Grid>
        </Grid>
      </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseaddGeneralMenu}> {t("Cancel")} </Button>
      <Button type="submit" autoFocus>
      {t("Save")} 
      </Button>
    </DialogActions>
    </form>
  </Dialog>
      );
};

const showDialogEditGeneralMenu = () => {
   
    return (
      <Dialog
      disableScrollLock
      fullWidth
      open={openeditGeneralMenu}
      onClose={handleCloseeditGeneralMenu}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <form onSubmit={handleEditGeneralMenuSubmit}>
      <DialogTitle id="alert-dialog-title">
      {t("Edit Request Type")} 
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description">
      </DialogContentText>
        <Grid container  >
          <Grid 
            sx={{alignSelf:"center"}}
            item xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Typography  variant="inherit"  component="h6">
              {t('Request Type(TH)')} :
            </Typography>
        </Grid>
        <Grid 
          item 
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
        >
          <TextField
            value={request_type_name_edit}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email_address"
            size="small"
            onChange={(e) => {
              setRequestTypeNameEdit(e.target.value);
            }}
            // value={dataAddGeneralMenu.menu_name}
          />
          </Grid>
        </Grid>
        
        <Grid container  >
            <Grid 
              sx={{alignSelf:"center",mt:2}}
              item xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
            <Typography  variant="inherit"  component="h6">
              {t('Request Type(EN)')} :
            </Typography>
          </Grid>
          <Grid item xs={12}
            sm={8}
            md={8}
            lg={8}
            xl={8}
          >
            <TextField
                value={request_type_name_en_edit}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email_address"
                size="small"
                onChange={(e) => {
                  setRequestTypeNameEngEdit(e.target.value);
                }}
                // value={dataAddGeneralMenu.menu_route}
              />
          </Grid>
        </Grid>
        <Grid container  >
            <Grid 
              sx={{alignSelf:"center",mt:2}}
              item xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
            <Typography  variant="inherit"  component="h6">
              {t('Reques Type Admin')} :
            </Typography>
          </Grid>
          <Grid 
            sx={{alignSelf:"center",mt:2}}
            item 
            xs={12}
            sm={8}
            md={8}
            lg={8}
            xl={8}
          >
              <Autocomplete
                  value={checkedrequesttypeedit}
                  multiple
                  size="small"
                  fullWidth
                  limitTags={10}
                  id="multiple-limit-tags"
                  options={user_list || null}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                  defaultValue={[]}
                  onChange={(event, newValue:any) => {
                    setcheckedrequesttypeEdit(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                    variant="outlined"
                    {...params} label={t("Select")}  />
                  )}
                  sx={{
                    //  width:500,
                    "& .MuiInput-underline:before": {
                      borderBottom: 1,
                      borderColor: "divider",
                    },
                  }}
              />
          </Grid>
        </Grid>
    </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseeditGeneralMenu}> {t("Cancel")} </Button>
        <Button type="submit" autoFocus>
        {t("Save")} 
        </Button>
      </DialogActions>
      </form>
    </Dialog>
        );
    };

  return (
    <Container fixed sx={{ mt: 3}} >
      <Box 
      sx={{}}
     >
          {/* Summary Icons */}
          <Grid 
          container item
          justifyContent="center"
          alignItems="center"
          sx={{
              margin: 'auto',
              width: '100%',
              maxWidth: '60rem'
          }}
          ><Typography variant="body1">{t(`Request Type Management Page`)}</Typography>

          </Grid>

          <Grid container justifyContent="flex-end">
              <Button 
                  size="medium"
                  variant="contained" 
                  sx={{
                      marginTop: 2,
                      marginLeft:2,
                      marginRight: 2,
                      minWidth: 200,
                      backgroundColor: "#c79059"
                  }}
                  className="requestButton"
                  onClick={() => { handleAddGeneralMenu()}}
              >
                  {t("Add Request Type")} 
              </Button>
          </Grid>
          <br />
          <Grid>
          <Grid container spacing={2}>
 
  <Grid item xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}>
   <TextField 
                  fullWidth 
                  label={t("Search...")}
                  variant="outlined"
                  size="small"
                  InputProps={{
                      endAdornment: (<IconButton><SearchIcon /></IconButton>)
                  }}
                  onChange={(e)=>{
                      requestSearch(e.target.value)
                      setSearchText(e.target.value)
                  }} 
                  sx={{
                      //  width:500,
                      "& .MuiInput-underline:before": {
                          borderBottom: 1,
                          borderColor: "divider",
                      },
                      marginBottom:2
                  }}
              />
  </Grid>
  <Grid item xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}>
  </Grid>
</Grid>
              
              <DataGrid
                  // components={{ Toolbar: CustomToolbar }}
                  sx={{ backgroundColor: "white"}}  
                  rows={tableData}
                  columns={requestColumns}
                  
                  // componentsProps={{
                  //     toolbar: {
                  //       value: searchText,
                  //       onChange: (searchVal: string) => requestSearch(searchVal),
                  //       onCancelSearch: () => cancelSearch()
                  //     }
                  // }}
                  
                  autoHeight
                  getRowHeight={() => 'auto'}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
              />
          </Grid>
          <br />
          <br />
          {showDialog()}
          {showDialogAddGeneralMenu()}
          {showDialogEditGeneralMenu()}
      </Box>
      </Container>
);
                }
export default GeneralMenu;
