import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
  Divider,
  Container,
  Paper,
  Grid,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { User } from "../../../../types/user.type";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../../reducers";
import * as loginActions from "../../../../actions/login.action";
import { useAppDispatch } from "../../../..";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CachedIcon from "@mui/icons-material/Cached";
import * as rolesActions from "../../../../actions/roles.action";
import * as generalmenuActions from "../../../../actions/generalmenu.action";
import * as requestTypeListActions from "../../../../actions/requesttype.list.action";
import * as menuconfigActions from "../../../../actions/menuconfig.action";
import { server, TOKEN } from "../../../../services/Constants";
import Autocomplete from '@mui/material/Autocomplete';
import Swal from "sweetalert2";
import i18next from 'i18next';
type RolesManagementAddProps = {
  //
};

const RolesManagementAdd: React.FC<any> = () => {
  const loginReducer = useSelector((state: RootReducers) => state.loginReducer);
  const rolesReducer = useSelector(
    (state: RootReducers) => state.rolesStateReducer
  );
  const generalMenuReducer = useSelector(
    (state: RootReducers) => state.generalMenuStateReducer
  );

  const requesTypeListReducer = useSelector((state: RootReducers) => state.requesTypeListReducer);
  const [dataAddRoles, setdataAddRoles] = React.useState({
    role: "",
    password: "",
  });
  let [menulist, setmenulist] = React.useState<any[]>([]);
  let [request_typelist, setrequest_typelist] = React.useState<any[]>([]);


  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const classes: SxProps<Theme> | any = {
    root: { display: "flex", justifyContent: "center" },
    buttons: { marginTop: 2 },
  };

  const [showPassword, setShowPassword] = React.useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [checkedmenu, setCheckedmenu] = React.useState([]);
  const [checkedrequesttype, setcheckedrequesttype] = React.useState([]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      //   navigate("/login");
      dispatch(loginActions.logouttologinpage(navigate));
      }
    dispatch(menuconfigActions.getMENU_CONFIGCheckRouteConfigmenu(window.location.pathname.replace("/", "").split("/")[0],navigate));
    dispatch(generalmenuActions.loadGENERAL_MENU());
    dispatch(requestTypeListActions.loadRequestTypeList());
  }, []);

  React.useEffect(() => {
    if(generalMenuReducer.result){
     setmenulist([...generalMenuReducer.result])
    }
   }, [generalMenuReducer.result]);

   React.useEffect(() => {
    if(requesTypeListReducer.result){
      requesTypeListReducer.result.forEach((item)=>{
        if(i18next.language == 'en'){
          item.request_name_show = item.request_type_name_en;
        }else if(i18next.language == 'th'){
          item.request_name_show = item.request_type_name;
        }else{
          return;
        }
      })
      setrequest_typelist([...requesTypeListReducer.result])
    }
   }, [requesTypeListReducer.result,i18next.language]);

   

  const handleChangecheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedList: any = checkedmenu;
    if (event.target.checked) {
      const newState = menulist.map((obj,i) => {
        if (i === parseInt(event.target.id)) {
          return {...obj, checked: true};
        }
        return obj;
      });
      setmenulist(newState);
      updatedList = [...checkedmenu, parseInt(event.target.value)];
    } else {
      let indexdel = -1;
      updatedList.forEach((item: any, index: number) => {
        if (parseInt(item) == parseInt(event.target.value)) {
          indexdel = index;
        }
      });
      if (indexdel !== -1) {
        const newStatedelete = menulist.map((obj,i:number) => {
          if (i === parseInt(event.target.id)) {
            
            return {...obj, checked: false};
          }
          return obj;
        });
        setmenulist(newStatedelete);
        updatedList.splice(indexdel, 1);
        
      }
    }
    setCheckedmenu(updatedList);
  };

  const handleaddRolesSubmit = (event: any) => {
    event.preventDefault();

    if(checkedmenu.length == 0){
      Swal.fire({
        title: `${t("Add Data")}`,
        text: `${t("Please Enter the Roles Permission")}`,
        icon: "error",
      });
return;
    }

    if(checkedrequesttype.length == 0){
      Swal.fire({
        title: `${t("Add Data")}`,
        text: `${t("Please Enter the Request Type Permission")}`,
        icon: "error",
      });
return;
    }
    dispatch(rolesActions.addRoles(dataAddRoles, checkedmenu,checkedrequesttype, navigate));
  };

  return (
    <Container fixed sx={{ mt: 3 }}>
      <form onSubmit={handleaddRolesSubmit}>
        <Box>
          <Grid
            container
            item
            // sx={{ ...gridStyles }}
          >
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              alignItems="center"
              justifyContent="center"
              direction="column"
              item
              container
            >
              <Typography component='div' variant="body1">{t(`Roles Management Add Page`)}</Typography>
            </Grid>
          </Grid>

          <Grid sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography component='div' variant="body1"
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                textAlign="left"
              >
                  {t(`Role Information`)}
                <Typography component='div' variant="body1"
                  sx={{ color: "red", display: "inline", fontSize: "22px" }}
                >
                  {" "}
                  *
                </Typography>
              </Typography>
              <Divider
                sx={{ borderBottomWidth: "initial", borderColor: "#00000061" }}
              ></Divider>
            </Grid>
          </Grid>

          <Grid container item sx={{ mt: 2 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="center"
              sx={{ alignSelf: "center" }}
            >
              <Typography component='div' variant="body1" sx={{}}>{t(`Role Name`)}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                required
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setdataAddRoles({
                    ...dataAddRoles,
                    role: e.target.value,
                  });
                }}
                value={dataAddRoles.role}
                InputProps={{}}
                sx={{
                  //  width:500,
                  "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid>

          <Grid sx={{ mt: 4 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography component='div' variant="body1"
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                textAlign="left"
              >
                {t(`Role Permission`)}
                <Typography component='div' variant="body1"
                  sx={{ color: "red", display: "inline", fontSize: "22px" }}
                >
                  {" "}
                  *
                </Typography>
              </Typography>
              <Divider
                sx={{ borderBottomWidth: "initial", borderColor: "#00000061" }}
              ></Divider>
            </Grid>
          </Grid>
          <Grid container item sx={{ mt: 2 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} textAlign="center">
              <FormGroup  sx={{ alignContent: "center" }} >
                  {menulist && (
                  <>
                    {menulist.map((item, index:any) => (
                      <FormControlLabel
                      key={index}
                        control={
                          <Checkbox
                         
                            checked={item.checked || false}
                            id={index.toString() || ""}
                            value={item.id.toString() || ""}
                            name={item.menu_name || ""}
                            onChange={handleChangecheckbox}
                          />
                        }
                        label={item.menu_name}
                      />
                    ))}
                    {/* <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Request Tracking"
              />
              <FormControlLabel control={<Checkbox />} label="Management" /> */}
                  </>
                )}
                {/* <FormControlLabel required disabled control={<Checkbox />} label="Disabled" /> */}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid>

          <Grid sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography component='div' variant="body1"
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                textAlign="left"
              >
                {t(`Request Type Permission`)}
                <Typography component='div' variant="body1"
                  sx={{ color: "red", display: "inline", fontSize: "22px" }}
                >
                  {" "}
                  *
                </Typography>
              </Typography>
              <Divider
                sx={{ borderBottomWidth: "initial", borderColor: "#00000061" }}
              ></Divider>
            </Grid>
          </Grid>

          <Grid container item sx={{ mt: 2 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="center"
              sx={{ alignSelf: "center" }}
            >
              <Typography component='div' variant="body1" sx={{}}>{t(`Request Type`)}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Autocomplete
      multiple
      size="small"
      fullWidth
      limitTags={10}
      id="multiple-limit-tags"
      options={request_typelist || null}
      getOptionLabel={(option) => option.request_name_show}
      isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
      defaultValue={[]}
      onChange={(event, newValue:any) => {
        setcheckedrequesttype(newValue);
      }}
      renderInput={(params) => (
        <TextField
        variant="outlined"
        {...params} label="Select" placeholder="Name" />
      )}
      sx={{
        //  width:500,
        "& .MuiInput-underline:before": {
          borderBottom: 1,
          borderColor: "divider",
        },
      }}
    />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid>

          <Grid sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography component='div' variant="body1"
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                textAlign="left"
              >
                {t(`Current User Identity Verification`)}
                <Typography component='div' variant="body1"
                  sx={{ color: "red", display: "inline", fontSize: "22px" }}
                >
                  {" "}
                  *
                </Typography>
              </Typography>
              <Divider
                sx={{ borderBottomWidth: "initial", borderColor: "#00000061" }}
              ></Divider>
            </Grid>
          </Grid>

          <Grid container item sx={{ mt: 2 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="center"
              sx={{ alignSelf: "center" }}
            >
              <Typography component='div' variant="body1" sx={{}}>{t(`Your Password`)}</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label={t("Password")}
                onChange={(e) => {
                  setdataAddRoles({
                    ...dataAddRoles,
                    password: e.target.value,
                  });
                }}
                value={dataAddRoles.password}
                type={showPassword ? "password" : "text"}
                autoComplete='new-password'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid>

          <Grid container item justifyContent="center" xs={12} sx={{ mt: 4 }}>
            <Button
              startIcon={<ArrowBackIcon />}
              variant="contained"
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                m: 2,
                width: 90,
                backgroundColor: "#73777B",
                "&:hover": { backgroundColor: "#2C394B" },
              }}
              className="requestButton"
            >
              {t(`Back`)}
            </Button>

            <Button
              startIcon={<CachedIcon />}
              variant="contained"
              onClick={(e)=>{window.location.reload();}}
              sx={{
                m: 2,
                width: 90,
                backgroundColor: "#FFFFFF",
                color: "#F36D0A",
                "&:hover": { backgroundColor: "#FFFFF4" },
              }}
              className="requestButton"
            >
              {t(`Reset`)}
            </Button>
            <Button
            type="submit"
              variant="contained"
              sx={{
                m: 2,
                width: 90,
                backgroundColor: "#c79059",
                "&:hover": { backgroundColor: "#c18346" },
              }}
              className="requestButton"
            >
              {t(`Save`)}
            </Button>
          </Grid>
        </Box>
      </form>
    </Container>
  );
};
export default RolesManagementAdd;
