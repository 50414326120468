import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import { Link, useNavigate,useMatch } from "react-router-dom";
import { useAppDispatch } from "../../..";
import { server, TOKEN,MEMBER_ID,reqeuestMassageFileUrl,reqeuestMassageImageUrl } from "../../../services/Constants";
import { Grid, Alert, Box, Button, Card, CardContent, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import dayjs, { Dayjs } from "dayjs";
import "./RequestSelectPage.css";
import * as loginActions from "../../../actions/login.action";
import * as RequestMassageActions from "../../../actions/request_massage.action";
import * as requestActions from "../../../actions/request.action";
import * as menuconfigActions from "../../../actions/menuconfig.action";
import Container from '@mui/material/Container';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CardMedia from '@mui/material/CardMedia';
import { useTranslation } from "react-i18next";
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import i18next from 'i18next';

const Swal = require('sweetalert2')



export default function RequestSelectPage() {
    const requesReducer = useSelector((state: RootReducers) => state.requesReducer);
    const RequestMassageStateReducer = useSelector((state: RootReducers) => state.RequestMassageStateReducer);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const match = useMatch("my-request-select/:id");
    const req_id = match?.params.id;
    const [skipped, setSkipped] = React.useState<any[]>([]);
    React.useEffect(() => {
        if (!localStorage.getItem(TOKEN)) {
            //   navigate("/login");
            dispatch(loginActions.logouttologinpage(navigate));
        }
        dispatch(menuconfigActions.getMENU_CONFIGCheckTokenOnly(navigate));
        const callawaitfunction = async () =>{
            await dispatch(RequestMassageActions.loadRequestMassage(Number(req_id)));
            await dispatch(requestActions.loadRequestByIdDetail(Number(req_id)));
        }
        callawaitfunction();
    }, []);
    const [reqestList, setRequestList] = React.useState<any[]>([]);
    React.useEffect(() => {
        if(requesReducer.result.length > 0){
            let  dataList:any[] = [];
            let index = 0;
            requesReducer.result.forEach((item) =>{
                if(Number(item.status) == 1)  {
                    item.request_status_show = `${t('Draft')}`;
                }else if(Number(item.status) == 2){
                    item.request_status_show = `${t('Submitted')}`;
                }else if(Number(item.status) == 3){
                    item.request_status_show = `${t('In Progress')}`;
                }else if(Number(item.status) == 4){
                    item.request_status_show = `${t(`Done`)}`;
                }else if(Number(item.status) == 5){
                    item.request_status_show = `${t('Cancel')}`;
                }else if(Number(item.status) == 6){
                    item.request_status_show = `${t('Overdue')}`;
                }else if(Number(item.status) == 7){
                    item.request_status_show = `${t('Needs Action')}`;
                }else if(Number(item.status) == 9){
                    item.request_status_show = `${t('Closed')}`;
                }else{
                    item.request_status_show = `${t('In Progress')}`;
                }
                if(i18next.language == 'en'){
                    item.request_name_show = item.request_type_name_en;
                    item.request_sub_show = item.request_sub_name_en;
                }else if(i18next.language == 'th'){
                    item.request_name_show = item.request_type_name;
                    item.request_sub_show = item.request_sub_name;
                }else{
                    return;
                }
                dataList[index] = item;
                index += 1;
            })
            console.log("dataListRequest")
            console.log(dataList)
            setRequestList(dataList)
        }
    }, [requesReducer.result,i18next.language]);

    React.useEffect(() => {
        
        if(requesReducer.res_skip.length > 0){
            let  dataList:any[] = [];
            requesReducer.res_skip.forEach((item,index) =>{
                if(Number(item) > 0){
                    dataList[index] = Number(item) - 1;
                }else{
                    dataList[index] = Number(item);
                }
        
            })
            console.log("res_skip:" + JSON.stringify(dataList))
            setSkipped(dataList)
        }
    }, [requesReducer.res_skip]);

    const [DataMassageList, setDataMassageList] = React.useState<any[]>([]);
    React.useEffect(() => {
        let  dataList:any[] = [];
        let index = 0;
        RequestMassageStateReducer.result.forEach((item) =>{
            dataList[index] = item;
            index += 1;
            if(item.file_name != ""){
                item.file_name_show = item.file_name.split(",");
            }else{
                item.file_name_show = []
            }
            if(item.image_name != ""){
                item.file_image_show = item.image_name.split(",");
            }else{
                item.file_image_show = []
            }
        })
        setDataMassageList(dataList)
    }, [RequestMassageStateReducer.result]);
    const [Reply, setReply] = React.useState("");
    React.useEffect(() => {
        if (RequestMassageStateReducer.isAdddata) {
            Swal.fire({
                title: `${t("Add Data")}`,
                text: `${t("Add Data Success")}`,
                icon: "success",
            });
            dispatch(RequestMassageActions.setMassageFetchingToState());
            dispatch(RequestMassageActions.loadRequestMassage(Number(req_id)));
        } 
    }, [RequestMassageStateReducer]);
    // data test


    //get data input
    const [datasheet_id, setCompanySelected] = React.useState(0);
    const [statusD, setStatusD] = React.useState("1");
    const [statusS, setStatusS] = React.useState("2");
    const [remark, setRemark] = React.useState("");
    
    const [req_type, setRequestTypeSelected] = React.useState<any[]>([]);

    const [req_sub_type, setRequestSubTypeSelected] = React.useState<any[]>([]);
    const start = Date.now();
    const [requestDate, setRequestDate] = React.useState<Dayjs | null>(dayjs(start));
    const [requestDateValue, setRequestValue] = React.useState<Dayjs | null>(dayjs(start));
    //date 
    const [DataRequesttableData, setDataRequesttableData] = React.useState<any[]>([]);
    const DataRequestColumns: GridColDef[] = [
        {
            headerName: "Descriptions",
            field: "request_no",
            flex: 1 
        },
        {
            headerName: "Data",
            field: "create_date",
            flex: 1
        },

    ];
    const steps = [
        `${t("Submitted")}`,
        `${t("In Progress")}`,
        `${t("Needs Action")}`,
        `${t("Overdue")}`,
        `${t("Cancel")}`,
        `${t("Done")}`,
        `${t("Closed")}`,
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    React.useEffect(() => {
        if(reqestList.length > 0){
            if(Number(reqestList[0].status) == 2){
                setActiveStep(0)
            }else if(Number(reqestList[0].status) == 8){
                setActiveStep(1)
            }else if(Number(reqestList[0].status) == 3){
                setActiveStep(1)
            }else if(Number(reqestList[0].status) == 7){
                setActiveStep(2)
            }else if(Number(reqestList[0].status) == 6){
                setActiveStep(3)
            }else if(Number(reqestList[0].status) == 5){
                setActiveStep(4)
            }else if(Number(reqestList[0].status) == 4){
                setActiveStep(5)
            }else if(Number(reqestList[0].status) == 9){
                setActiveStep(6)
            }else {
                setActiveStep(0)
            }
        }
    }, [reqestList]);
//   const handleNext = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };

    const ScrollContainer = styled('div')({
        maxHeight: '500px',
        height:'auto',
        overflowY: 'scroll',
    });
    const Content = styled('div')({
        marginRight: "5px",
        height: 'auto',
    });

    const scrollContainerRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
    }, []);

    const handleadRequestMS = (event: any) => {
        event.preventDefault(); 
        let formData = new FormData();
        if(!Reply || Reply == ""){
            Swal.fire({
                title: `${t("Check Data")}`,
                text: `กรุณาเพิ่มข้อมูล`,
                icon: "warning",
            });
            return;
        }   
        if (imageName.length > 0) {
            let getImagename = "";
            imageName.forEach((item, index) => {
              formData.append(`file`, item);
              if (index > 0) {
                getImagename += `,${item.name.replace(/\s/g, '_')}`;
              } else {
                getImagename += `${item.name.replace(/\s/g, '_')}`;
              }
            });
            formData.append(`image_name`, getImagename);
        }else{
            formData.append(`image_name`, "");
        }
        if (FileName.length > 0) {
            let getfilename = "";
            FileName.forEach((item, index) => {
              formData.append(`file`, item);
              if (index > 0) {
                getfilename += `,${item.name.replace(/\s/g, '_')}`;
              } else {
                getfilename += `${item.name.replace(/\s/g, '_')}`;
              }
            });
            formData.append(`file_name`, getfilename);
        }else{
            formData.append(`file_name`, "");
        }

        formData.append(`request_id`, `${req_id}`);
        formData.append(`user_create`, `${localStorage.getItem(MEMBER_ID)}`);
        formData.append(`sequence`, "0");
        formData.append(`description`, Reply);
        dispatch(RequestMassageActions.addRequestMassage(formData,navigate));
        let clear = "";
        setReply(clear)
        setFileName([])
        setFilebase64([])
        setImageName([])
        setImageFilebase64([])
    };
    
    //-------------------------------------------file uoload -------------------------------//
    const [Filebase64, setFilebase64] = React.useState<any[]>([]);
    const [FileName, setFileName] = React.useState<any[]>([]);

    const handleFileUpload = (event: any) => {
        let input = event.target;
        let count = input.files.length;
        let index = 0;
        if (input.files) {
            while (count--) {
                let reader = new FileReader();
                reader.onload = (e:any) => {
                        // base64
                    let newStatebase64 = Filebase64;
                    newStatebase64.push(e.target.result);
                    setFilebase64([...newStatebase64]);
  
                };
                if (input.files[index].type === "application/pdf") {
                        // data
                    let newState = FileName;
                    // newState[0] = input.files[index]
                        // setImageFile([...imageFile, input.files[index]]);
                    newState.push(input.files[index]);

                    setFileName([...newState]);
                    reader.readAsDataURL(input.files[index]);

                }
                index++;
            }
           
        }
    };
    const [imageFilebase64, setImageFilebase64] = React.useState<any[]>([]);
    const [imageName, setImageName] = React.useState<any[]>([]);
    const handleFileUploadImage = (event: any) => {
        console.log(imageFilebase64)
        let input = event.target;
        let count = input.files.length;
        let index = 0;
        if (input.files) {
            while (count--) {
                let reader = new FileReader();
                reader.onload = (e:any) => {
  
                        // base64
                    let newStatebase64 = imageFilebase64;
                    newStatebase64.push(e.target.result);
                    // setImageFilebase64(newStatebase64)
                    setImageFilebase64([...newStatebase64]);

                };
                if (input.files[index].type.includes("image")) { // ตรวจสอบว่าไฟล์เป็นรูปภาพหรือไม่
                    let newState = imageName;
                    newState.push(input.files[index]);
                    setImageName(newState);
                    reader.readAsDataURL(input.files[index]);
                }
                index++;
            }
            console.log("imageName")
            console.log(imageName)
            console.log(imageFilebase64)
        }
    };
    
    const handleDeleteFile = (index: number) => {
        let split_file = [...FileName];
        let split_filebase64 = [...Filebase64];

        split_file.splice(index,1);
        split_filebase64.splice(index,1);


        setFileName([...split_file])
        setFilebase64([...split_filebase64])



    }
    const handleDeleteImage = (index: number) => {
        let split_image = [...imageName];
        let split_imagefilebase64 = [...imageFilebase64];

        split_image.splice(index,1);
        split_imagefilebase64.splice(index,1);


        setImageName([...split_image])
        setImageFilebase64([...split_imagefilebase64])
    }
    //------------------------------------end file upload ------------------------------//
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [imageNameShow, setImageNameShow] = React.useState(String);
    const [indexOpenImage, setindexOpenImage] = React.useState(Number);

    const handleOpenDialog = (index:number,imageName:string) => {
        setindexOpenImage(index);
        setImageNameShow(imageName);
        setDialogOpen(true);
    }
  
    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const [dialogOpenBox, setDialogOpenBox] = React.useState(false);
    const [imageNameShowBox, setimageNameShowBox] = React.useState(String);
    const handleOpenDialogBox = (imageName:string) => {
        setimageNameShowBox(imageName);
        setDialogOpenBox(true);
    }
  
    const handleCloseDialogBox = () => {
        setDialogOpenBox(false);
    }
    const ClearValue = () => {
        window.location.reload();
    };
    return (
        <Container fixed sx={{ mt: 3}} >
        <Grid  
            xs={12} sm={12} md={12} lg={12} xl={12} 
            container
            justifyContent="center"
            sx={
                { 
                    margin: '0 auto'
                }
            }
        >
            <Card 
                sx={
                    { 
                        borderRadius:'25px',
                        marginTop:2,
                        minWidth: '40%',
                        boxShadow: 'unset',
                        width: '100%',
                    }
                }
            >
                <CardContent>
                    <Grid container>
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item
                            sx={{
                            
                            }}
                        >
                            <Button 
                                sx={{
                                    width: 90,
                                    borderColor: 'black',
                                    color: 'black',
                                    marginBottom: "15px"
                                }}
                                variant="outlined"
                                onClick={() => {
                                    navigate("/my-request")
                                }}
                            >
                                {t(`<Back`)}
                            </Button>
                        </Grid>
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item
                            sx={{
                                borderBottom: '#ABA9A6 solid 2px',
                                marginBottom: "2rem"
                            }}
                        >
                            <Typography 
                                gutterBottom 
                                variant="h5" 
                                component="h2" 
                                sx={{
                                    color:'#4F4A41',
                                    textAlign: "left",
                                    marginTop: 2,
                                }}
                            >
                                {t(`Follow up Request`)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        {/* datatable */}
                        {
                            reqestList.length > 0 && (
                            <>
                            <Grid 
                                xs={12} sm={12} md={5} lg={5} xl={5} 
                                direction="column" 
                                container item
                                sx={{
                                    
                                    marginBottom: '1rem'
                                }}
                            >
                                <Grid container>
                                    <Grid 
                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                        sx={{
                                            
                                            marginBottom: '1rem'
                                        }}
                                    >
                                        <Accordion
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                id="panel1a-header"
                                                sx={{
                                                    bgcolor: '#CE9461' 
                                                }}
                                            >
                                                <Typography>{t(`Request Details`)}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid 
                                                    container
                                                    sx={{
                                                        borderTop: '#ABA9A6 solid 1px',
                                                        borderBottom: '#ABA9A6 solid 1px',
                                                        borderRight: '#ABA9A6 solid 1px',
                                                        borderLeft: '#ABA9A6 solid 1px',
                                                    }}
                                                >
                                                    <Grid 
                                                        xs={12} sm={4} md={4} lg={4} xl={4} 
                                                        sx={{
                                                            padding: "0.5rem",   
                                                            borderRight: '#ABA9A6 solid 1px',
                                                        }}
                                                    >
                                                        <Typography>{t(`Business Name`)}</Typography>

                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={8} md={8} lg={8} xl={8} 
                                                        sx={{
                                                            padding: "0.5rem",
                                                        }}
                                                    >
                                                        {reqestList[0].company_name}
                                                    </Grid>
                                                </Grid>
                                                <Grid 
                                                    container
                                                    sx={{
                                                        borderBottom: '#ABA9A6 solid 1px',
                                                        borderRight: '#ABA9A6 solid 1px',
                                                        borderLeft: '#ABA9A6 solid 1px',
                                                    }}
                                                >
                                                    <Grid 
                                                        xs={12} sm={4} md={4} lg={4} xl={4} 
                                                        sx={{
                                                            padding: "0.5rem",   
                                                            borderRight: '#ABA9A6 solid 1px',
                                                        }}
                                                    >
                                                        <Typography>{t(`Requested Person`)}</Typography>

                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={8} md={8} lg={8} xl={8} 
                                                        sx={{
                                                            padding: "0.5rem",
                                                        }}
                                                    >
                                                        {reqestList[0].contact_name}
                                                    </Grid>
                                                </Grid>
                                                <Grid 
                                                    container
                                                    sx={{
                                                        borderBottom: '#ABA9A6 solid 1px',
                                                        borderRight: '#ABA9A6 solid 1px',
                                                        borderLeft: '#ABA9A6 solid 1px',
                                                    }}
                                                >
                                                    <Grid 
                                                        xs={12} sm={4} md={4} lg={4} xl={4} 
                                                        sx={{
                                                            padding: "0.5rem",   
                                                            borderRight: '#ABA9A6 solid 1px',
                                                        }}
                                                    >
                                                        <Typography>{t(`Requested Date`)}</Typography>

                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={8} md={8} lg={8} xl={8} 
                                                        sx={{
                                                            
                                                            padding: "0.5rem",
                                                        }}
                                                    >
                                                        {reqestList[0].create_as}
                                                    </Grid>
                                                </Grid>
                                                <Grid 
                                                    container
                                                    sx={{
                                                        borderBottom: '#ABA9A6 solid 1px',
                                                        borderRight: '#ABA9A6 solid 1px',
                                                        borderLeft: '#ABA9A6 solid 1px',
                                                    }}
                                                >
                                                    <Grid 
                                                        xs={12} sm={4} md={4} lg={4} xl={4} 
                                                        sx={{
                                                            padding: "0.5rem",   
                                                            borderRight: '#ABA9A6 solid 1px',
                                                        }}
                                                    >
                                                        <Typography>{t(`Request Due Date`)}</Typography>

                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={8} md={8} lg={8} xl={8} 
                                                        sx={{
                                                            padding: "0.5rem",
                                                        }}
                                                    >
                                                        {reqestList[0].duedate}
                                                    </Grid>
                                                </Grid>
                                                <Grid 
                                                    container
                                                    sx={{
                                                        borderBottom: '#ABA9A6 solid 1px',
                                                        borderRight: '#ABA9A6 solid 1px',
                                                        borderLeft: '#ABA9A6 solid 1px',
                                                    }}
                                                >
                                                    <Grid 
                                                        xs={12} sm={4} md={4} lg={4} xl={4} 
                                                        sx={{
                                                            padding: "0.5rem",   
                                                            borderRight: '#ABA9A6 solid 1px',
                                                        }}
                                                    >
                                                        <Typography>{t(`Latest Update`)}</Typography>

                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={8} md={8} lg={8} xl={8} 
                                                        sx={{
                                                            padding: "0.5rem",
                                                        }}
                                                    >
                                                        {reqestList[0].update_as}
                                                    </Grid>
                                                </Grid>
                                                <Grid 
                                                    container
                                                    sx={{
                                                        borderBottom: '#ABA9A6 solid 1px',
                                                        borderRight: '#ABA9A6 solid 1px',
                                                        borderLeft: '#ABA9A6 solid 1px',
                                                    }}
                                                >
                                                    <Grid 
                                                        xs={12} sm={4} md={4} lg={4} xl={4} 
                                                        sx={{
                                                            padding: "0.5rem",   
                                                            borderRight: '#ABA9A6 solid 1px',
                                                        }}
                                                    >
                                                        <Typography>{t(`Status`)}</Typography>

                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={8} md={8} lg={8} xl={8} 
                                                        sx={{
                                                            padding: "0.5rem",
                                                        }}
                                                    >
                                                        {reqestList[0].request_status_show}
                                                    </Grid>
                                                </Grid>
                                                <Grid 
                                                    container
                                                    sx={{
                                                        borderBottom: '#ABA9A6 solid 1px',
                                                        borderRight: '#ABA9A6 solid 1px',
                                                        borderLeft: '#ABA9A6 solid 1px',
                                                    }}
                                                >
                                                    <Grid 
                                                        xs={12} sm={4} md={4} lg={4} xl={4} 
                                                        sx={{
                                                            padding: "0.5rem",   
                                                            borderRight: '#ABA9A6 solid 1px',
                                                        }}
                                                    >
                                                        <Typography>{t(`Ticket Owner`)}</Typography>

                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={8} md={8} lg={8} xl={8} 
                                                        sx={{
                                                            padding: "0.5rem",
                                                        }}
                                                    >
                                                        {reqestList[0].admin_name}
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container>
                                    <Grid 
                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                        sx={{
                                            
                                            marginBottom: '1rem'
                                        }}
                                    >
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                id="panel1a-header"
                                                sx={{
                                                    bgcolor: '#CE9461' 
                                                }}
                                            >
                                                <Typography>{t(`Request`)}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid 
                                                    container
                                                    sx={{
                                                        borderTop: '#ABA9A6 solid 1px',
                                                        borderBottom: '#ABA9A6 solid 1px',
                                                        borderRight: '#ABA9A6 solid 1px',
                                                        borderLeft: '#ABA9A6 solid 1px',
                                                    }}
                                                >
                                                    <Grid 
                                                        xs={12} sm={4} md={4} lg={4} xl={4} 
                                                        sx={{
                                                            padding: "0.5rem",   
                                                            borderRight: '#ABA9A6 solid 1px',
                                                        }}
                                                    >
                                                        <Typography>{t(`Request Type`)}</Typography>

                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={8} md={8} lg={8} xl={8} 
                                                        sx={{
                                                            padding: "0.5rem",
                                                        }}
                                                    >
                                                        {reqestList[0].request_name_show}
                                                    </Grid>
                                                </Grid>
                                                <Grid 
                                                    container
                                                    sx={{
                                                        borderBottom: '#ABA9A6 solid 1px',
                                                        borderRight: '#ABA9A6 solid 1px',
                                                        borderLeft: '#ABA9A6 solid 1px',
                                                    }}
                                                >
                                                    <Grid 
                                                        xs={12} sm={4} md={4} lg={4} xl={4} 
                                                        sx={{
                                                            padding: "0.5rem",   
                                                            borderRight: '#ABA9A6 solid 1px',
                                                        }}
                                                    >
                                                        <Typography>{t(`Sub Request Type`)}</Typography>

                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={8} md={8} lg={8} xl={8} 
                                                        sx={{
                                                            padding: "0.5rem",
                                                        }}
                                                    >
                                                    {
                                                        reqestList.length > 0 &&
                                                        reqestList.map((item, index) => (
                                                            index < (reqestList.length-1) ?(
                                                                <>
                                                                
                                                                    {item.request_sub_show} ,<br />
                                                                </>
                                                            ):(
                                                                <>
                                                                    {item.request_sub_show}
                                                                </>
                                                            )
                                                        ))
                                                    }
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container>
                                    <Grid 
                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                        sx={{
                                            
                                            marginBottom: '1rem'
                                        }}
                                    >
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                id="panel1a-header"
                                                sx={{
                                                    bgcolor: '#CE9461' 
                                                }}
                                            >
                                                <Typography>{t(`All Files`)}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid 
                                                    container
                                                    sx={{
                                                    }}
                                                >
                                                    {
                                                        DataMassageList.length > 0 && 
                                                        DataMassageList.map((item, index) => (
                                                            item.file_name_show.map((data:any, indexf:number) => (
                                                                                        
                                                                <>
                                                                <Grid 
                                                                    xs={12} sm={12} md={12} lg={12} xl={12} 
                                                                    sx={{
                                                                        paddingRight: "0.5rem",   
                                                                        paddingTop: "0.5rem",   
                                                                        borderBottom: '#ABA9A6 solid 1px',
                                                                    }}
                                                                >
                                                                    <Button  
                                                                        size="small"
                                                                        href="#"
                                                                        target="_blank"
                                                                        onClick={() => {
                                                                            let pdf_newTab:any = window.open("","_blank");
                                                                            pdf_newTab.document.write(
                                                                                "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                                                                                    `${reqeuestMassageFileUrl}/${item.file_name_show[indexf]}` +
                                                                                "'></iframe></body></html>"
                                                                            );
                                                                            pdf_newTab.document.close();
                                                                        }} 
                                                                    >
                                                                        {item.file_name_show[indexf]}
                                                                    </Button>
                                                                </Grid>
                                                                </>
                                                            ))
                                                        ))
                                                    }
                                                    
                                                </Grid>
                                                <Grid 
                                                    container
                                                    sx={{
                                                    }}
                                                >
                                                    {
                                                        DataMassageList.length > 0 && 
                                                        DataMassageList.map((item, index) => (
                                                            item.file_image_show.map((data:any, indexf:number) => (
                                                                                        
                                                                <>
                                                                <Grid 
                                                                    xs={12} sm={12} md={12} lg={12} xl={12} 
                                                                    sx={{
                                                                        paddingRight: "0.5rem",   
                                                                        paddingTop: "0.5rem",   
                                                                        borderBottom: '#ABA9A6 solid 1px',
                                                                    }}
                                                                >
                                                                    <Button  
                                                                        size="small"
                                                                        href="#"
                                                                        target="_blank"
                                                                        sx={{
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleOpenDialogBox(item.file_image_show[indexf]);
                                                                        }}
                                                                    >
                                                                        {item.file_image_show[indexf]}
                                                                    </Button>
                                                                </Grid>
                                                                </>
                                                            ))
                                                        ))
                                                    }
                                                    
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>

                            </Grid>
                            
                            <Grid 
                                xs={12} sm={12} md={1} lg={1} xl={1} 
                                direction="column" 
                                container item
                                sx={{
                                    
                                    marginBottom: '1rem'
                                }}
                            >

                            </Grid>
                            </>
                            )
                        }
                        {/* data msege */}
                        <Grid 
                            xs={12} sm={12} md={6} lg={6} xl={6} 
                            direction="column" 
                            container item
                            sx={{
                                
                                marginBottom: '1rem'
                            }}
                        >
                            <Grid container>
                                <Grid 
                                    xs={12} sm={12} md={12} lg={12} xl={12} 
                                    sx={{
                                        textAlign: 'left',
                                        marginBottom: '1rem'
                                    }}
                                >
                                    <Typography variant="body1">
                                        {t(`Status`)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid 
                                    xs={12} sm={12} md={12} lg={12} xl={12} 
                                    sx={{
                                        
                                        marginBottom: '1rem'
                                    }}
                                >
                                    <Box sx={{ width: '100%' }}>
                                        <Stepper activeStep={activeStep} alternativeLabel>
                                        {
                                        steps.map((label, index) => {
                                            const stepProps: { completed?: boolean } = {};
                                            skipped.map((item)=>{
                                                if (Number(item) == index) {
                                                    stepProps.completed = false;
                                                }
                                            })

                                                return (
                                                    <Step key={label} {...stepProps}>
                                                    <StepLabel>{label}</StepLabel>
                                                    </Step>
                                                );
                                            })
                                        }
                                        </Stepper>
                                        {/* <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            sx={{ marginTop: '20px' }}
                                        >
                                            Next
                                        </Button> */}
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container>
                                <Grid 
                                    xs={12} sm={12} md={12} lg={12} xl={12} 
                                    sx={{
                                        
                                        marginBottom: '1rem'
                                    }}
                                >
                                    {
                                        DataMassageList.length > 0 && 
                                        <ScrollContainer ref={scrollContainerRef}>
                                            <Content>

                                           
                                            
                                                <>
                                                {
                                                    DataMassageList.map((item, index) => (
                                                        <>
                                                            
                                                            
                                                            <Grid container>
                                                                <Grid 
                                                                    xs={12} sm={6} md={6} lg={6} xl={6} 
                                                                    sx={{
                                                                        textAlign:"left"
                                                                        
                                                                    }}
                                                                >
                                                                    {item.name_show}
                                                                </Grid>
                                                                <Grid 
                                                                    xs={6} sm={6} md={6} lg={6} xl={6} 
                                                                    sx={{
                                                                        
                                                                       textAlign:"right"
                                                                    }}
                                                                >
                                                                    {item.created_at_date}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid 
                                                                    xs={12} sm={12} md={12} lg={12} xl={12} 
                                                                    sx={{
                                                                        
                                                                        marginBottom: '2rem'
                                                                    }}
                                                                >
                                                                    <Box
                                                                            sx={{
                                                                                width: "100%",
                                                                                minHeight: 100,
                                                                                border: 1,
                                                                                borderColor: 'grey.500',
                                                                                borderRadius: 1
                                                                                
                                                                            }}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                width: "100%",
                                                                                minHeight: 100,    
                                                                            }}
                                                                        >
                                                                            <Typography sx={{marginLeft: '0.5rem',marginTop:'0.5rem'}}>
                                                                                {item.description}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box
                                                                            sx={{
                                                                                width: "100%",
                                                                                minHeight: 20, 
                                                                                textAlign: "right"
                                                                            }}
                                                                        >
                                                                        {
    
                                                                            item.file_name_show.map((data:any, indexf:number) => (
                                                                                <Button  
                                                                                    size="small"
                                                                                    sx={{
                                                                                        marginRight: '0.5rem',
                                                                                        marginBottom:'0.5rem',
                                                                                        border: 1,
                                                                                        borderRadius: 5,
                                                                                        fontSize: "10px",
                                                                                        color: '#CE9461',
                                                                                        '&:hover': {color: '#CC704B'},
                                                                                    }}
                                                                                    href="#"
                                                                                    target="_blank"
                                                                                    onClick={() => {
                                                                                    let pdf_newTab:any = window.open("","_blank");
                                                                                    pdf_newTab.document.write(
                                                                                        "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                                                                                        `${reqeuestMassageFileUrl}/${item.file_name_show[indexf]}` +
                                                                                        "'></iframe></body></html>"
                                                                                    );
                                                                                    pdf_newTab.document.close();
                                                                                }} >
                                                                                    {item.file_name_show[indexf]}
                                                                                </Button>
                                                                            ))
                                                                        }
                                                                        </Box>
                                                                        <Box
                                                                            sx={{
                                                                                width: "100%",
                                                                                minHeight: 20, 
                                                                                textAlign: "right"
                                                                            }}
                                                                        >
                                                                        {
    
                                                                            item.file_image_show.map((data:any, indexf:number) => (
                                                                        
                                                                                <>
                                                                                <Button  
                                                                                    size="small"
                                                                                    href="#"
                                                                                    target="_blank"
                                                                                    sx={{
                                                                                        marginRight: '0.5rem',
                                                                                        marginBottom:'0.5rem',
                                                                                        border: 1,
                                                                                        borderRadius: 5,
                                                                                        fontSize: "10px",
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        handleOpenDialogBox(item.file_image_show[indexf]);
                                                                                    }}
                                                                                >
                                                                                    {item.file_image_show[indexf]}
                                                                                </Button>
            
                                                                                </>
                                                                            ))
                                                                        }
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    ))
                                                }
                                                </>
                                           
                                            </Content>
                                        </ScrollContainer>
                                    }
                                    <Dialog open={dialogOpenBox} onClose={handleCloseDialogBox}>
                                        <DialogTitle>File Name: {imageNameShowBox}</DialogTitle>
                                        <DialogContent>
                                            <CardMedia
                                                width="auto"
                                                component="img"
                                                height="40%"
                                                src={`${reqeuestMassageImageUrl}/${imageNameShowBox}`}
                                                                                        />
                                         </DialogContent>
                                    </Dialog>
                                </Grid>
                            </Grid>
                            <br />
                            <form onSubmit={handleadRequestMS}>
                                <Grid container>
                                    <Grid 
                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                        sx={{
                                            
                                            marginBottom: '1rem'
                                        }}
                                    >
                                        <TextField
                                            value={Reply}
                                            onChange={(e) => {     
                                                setReply(e.target.value);         
                                            }}
                                            label={t(`Reply`)}
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            
                                <Grid container>
                                    <Grid 
                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                        sx={{
                                            textAlign: 'left',
                                            marginBottom: '1rem'
                                        }}
                                    >    
                                        <Grid container>
                                        {
                                            FileName.length > 0 && 
                                            <>
                                            
                                                <Grid 
                                                    xs={12} sm={1} md={1} lg={1} xl={1} 
                                                    sx={{
                                                        textAlign: 'left',
                                                        marginBottom: '1rem',
                                                        paddingTop: '0.1rem'
                                                    }}
                                                >File:</Grid> 
                                                <Grid 
                                                    xs={11} sm={11} md={11} lg={11} xl={11} 
                                                    sx={{
                                                        textAlign: 'left',
                                                        marginBottom: '1rem',
                                                        paddingLeft: '1rem',
                                                    }}
                                                >
                                            
                                                    {
                                                        React.Children.toArray(
                                                            FileName.map((item, index) => (
                                                                <>
                                                                <Button  
                                                                    size="small"
                                                                    href="#"
                                                                    target="_blank"
                                                                    onClick={() => {
                                                                    let pdf_newTab:any = window.open("", "_blank");
                                                                    pdf_newTab.document.write(
                                                                        "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                                                                        Filebase64[index] +
                                                                        "'></iframe></body></html>"
                                                                    );
                                                                    pdf_newTab.document.close();
                                                                }} >
                                                                    {item.name} &nbsp;

                                                                </Button>
                                                                <HighlightOffIcon
                                                                        onClick={(e) => {     
                                                                            handleDeleteFile(index);        
                                                                        }}
                                                                        fontSize="small"
                                                                        sx={{
                                                                            color: "red",
                                                                            verticalAlign: "middle",
                                                                        }}
                                                                />
                                                                <br />
                                                                </>
                                                        ))
                                                    )}
                                                </Grid> 
                                            </>
                                        }
                                        </Grid>
                                    </Grid>
                                </Grid>   
                                <Grid container>
                                    <Grid 
                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                        sx={{
                                            textAlign: 'left',
                                            marginBottom: '1rem'
                                        }}
                                    >    
                                        <Grid container>
                                        {
                                            imageName.length > 0 && 
                                            <>
                                                <Grid 
                                                    xs={12} sm={1} md={1} lg={1} xl={1} 
                                                    sx={{
                                                        textAlign: 'left',
                                                        marginBottom: '1rem',
                                                        paddingTop: '0.2rem'
                                                    }}
                                                >Image:</Grid>
                                                
                                                <Grid 
                                                    xs={11} sm={11} md={11} lg={11} xl={11} 
                                                    sx={{
                                                        textAlign: 'left',
                                                        marginBottom: '1rem',
                                                        paddingLeft: '1rem',
                                                        paddingTop: '0.1rem'
                                                    }}
                                                >

                                                    {
                                                    
                                                        React.Children.toArray(
                                                            imageName.map((item, index) => (

                                                                // <CardMedia component='img' src={`${imageFilebase64[index]}`} />
                                                                <>
                                                                <Button  
                                                                    size="small"
                                                                    href="#"
                                                                    target="_blank"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleOpenDialog(index,item.name);
                                                                    }}
                                                                >
                                                                    {item.name}
                                                                </Button>
                                                                <HighlightOffIcon
                                                                        onClick={(e) => {     
                                                                            handleDeleteImage(index);        
                                                                        }}
                                                                        fontSize="small"
                                                                        sx={{
                                                                            color: "red",
                                                                            verticalAlign: "middle",
                                                                        }}
                                                                />
                                                                <br />
                                                                </>
                                                            ))
                                                        )
                                                    }
                                                </Grid> 
                                            </>
                                        }
                                            <Dialog aria-label="button" open={dialogOpen} onClose={handleCloseDialog}>
                                                <DialogTitle>File Name: {imageNameShow}</DialogTitle>
                                                <DialogContent>
                                                    <CardMedia
                                                        width="auto"
                                                        component="img"
                                                        height="40%"
                                                        image={imageFilebase64[indexOpenImage]}
                                                                        />
                                                </DialogContent>
                                            </Dialog>
                                        </Grid>
                                    </Grid>
                                </Grid>   
                                
                                <Grid container>
                                    <Grid 
                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                        sx={{
                                            textAlign: 'right',
                                            marginBottom: '1rem'
                                        }}
                                    >    
                                        <>
                                            <input 
                                                hidden 
                                                accept="image/*" 
                                                id="image-file" 
                                                multiple={true} 
                                                type="file"
                                                onChange={handleFileUploadImage} 
                                            />
                                            <label htmlFor="image-file">
                                                <IconButton 
                                                    aria-label="upload"
                                                    component="span" 
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    <AddPhotoAlternateIcon 
                                                        sx={{
                                                            color: '#CE9461',
                                                            '&:hover': {color: '#CC704B'},
                                                            fontSize: 30
                                                        }}
                                                     />
                                                </IconButton>
                                            </label>
                                        </>
                                        <>
                                            <input 
                                                hidden 
                                                accept=".pdf" 
                                                id="file" 
                                                multiple={true} 
                                                type="file"
                                                onChange={handleFileUpload} 
                                            />
                                            <label htmlFor="file">
                                                <IconButton 
                                                    aria-label="upload"
                                                    component="span" 
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    <AttachFileIcon 
                                                        sx={{
                                                            color: '#CE9461',
                                                            '&:hover': {color: '#CC704B'},
                                                            marginRight:'6px',
                                                            fontSize: 27
                                                        }}
                                                     />
                                                </IconButton>
                                            </label>
                                        </>
                                        <Button 
                                            type="submit"
                                            sx={{
                                                width: 90,
                                                backgroundColor: '#3E8E7E',
                                                '&:hover': {backgroundColor: '#125B50'}
                                            }}
                                            variant="contained"
                                        >
                                            {t(`Send`)}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>       
                        </Grid>
                    </Grid>
                    <Grid 
                        container   justifyContent="center"
                        xs={12}
                    >
{/* 
                        <Button 
                            variant="contained"
                            onClick={SaveDraftRequest}
                            sx={{
                                width: 90,
                                backgroundColor: "#73777B",
                                '&:hover': {backgroundColor: '#2C394B'}
                            }}
                            className="requestButton"
                        >
                            Draft
                        </Button> */}
                        {/* <Button 
                            sx={{
                                marginLeft:2,
                                marginRight: 2,
                                width: 90,
                                backgroundColor: "#3E8E7E",
                                '&:hover': {backgroundColor: '#125B50'}
                            }}
                            className="requestButton"
                            variant="contained"
                            onClick={SaveSubmitRequest}
                        >
                            Submit
                        </Button> */}
                        {/* <Button 
                            sx={{
                                width: 90,
                                backgroundColor: '#A84448'
                            }}
                            className="requestButton"
                            variant="contained"
                            color="error"
                            onClick={() => {
                                ClearValue()
                            }}
                        >
                            Clear
                        </Button> */}
                        {/* <Button 
                            sx={{
                                width: 90,
                                backgroundColor: '#CE9461',
                                '&:hover': {backgroundColor: '#CC704B'}
                            }}
                            variant="contained"
                            onClick={() => {
                                navigate("/my-request")
                            }}
                        >
                            {t(`Back`)}
                        </Button> */}
                        
                    </Grid>
                    <br />
                </CardContent>
            </Card>
        </Grid>
    </Container>                       
  );
}