// import { history } from "../index";
import * as React from "react";
import {
    server,
    REQUEST_CREATE_FETCHING,
    REQUEST_CREATE_SUCCESS,
    REQUEST_CREATE_FAILED,
    MEMBER_ID,
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import i18n from 'i18next';
const Swal = require('sweetalert2')

export const setRequestCreateFetchingToState = () => ({
    type: REQUEST_CREATE_FETCHING,
});
  
export const setRequestCreateSuccessToState = () => ({
    type: REQUEST_CREATE_SUCCESS,
});
  
export const setRequestCreateFailedToState = () => ({
    type: REQUEST_CREATE_FAILED,
});

export const addRequestCreate = (          
        datasheet_id: number,
        req_type: number,
        req_sub_type: any[],
        reqDate: any,
        remark: string,
        status :string,
        navigate: any
    ) => {
    return async(dispatch: any) => {
        dispatch(setRequestCreateFetchingToState());
            // let request_id_data: number;
        let addDataRequest = {
            datasheet_id: datasheet_id,
            request_type: req_type,
            status: status,
            remark: remark,
            duedate: reqDate,
            user_create : localStorage.getItem(MEMBER_ID),
            user_update : localStorage.getItem(MEMBER_ID),
        }
            
        let result_add = await httpClient.post(`${server.REQUEST}/add`, addDataRequest);
        // request_id_data = result_add.data.id;
        var addRequestDetail:any = {}
        let CheakAdd = 0;
        if(result_add.status == 200 || result_add.status == 201){
            for(let i=0;i< req_sub_type.length;i++){
                addRequestDetail = {
                    request_id: result_add.data.id,
                    sub_type_id: Number(req_sub_type[i].request_sub_type_id),
                    user_create: localStorage.getItem(MEMBER_ID),
                    user_update : localStorage.getItem(MEMBER_ID),
                }
                let result_addDetail = await httpClient.post(`${server.REQUEST_DETAIL}/add`, addRequestDetail);
                if(result_addDetail.status == 200 || result_addDetail.status == 201){
                }else{
                    CheakAdd += 1;
                }
            }
            if(CheakAdd > 0){
                await httpClient.delete(`${server.REQUEST}/${result_add.data.id}`);
                await httpClient.delete(`${server.REQUEST_DETAIL}/${result_add.data.id}`);
                Swal.fire({
                    title: `${i18n.t(`Save Data`)}`,
                    text: `${i18n.t(`Save Data Fail`)}`,
                    icon: "error",
                });
                return; 
            }else{

                dispatch(setRequestCreateSuccessToState());
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${i18n.t(`Save Data Success`)}`,
                    showConfirmButton: false,
                    timer: 1500
                })
                navigate("/my-request");
            }
        }else{
            Swal.fire({
                title: `${i18n.t(`Save Data`)}`,
                text: `${i18n.t(`Save Data Fail`)}`,
                icon: "error",
            });
            return;
        }        
    };
};
  