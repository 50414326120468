import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { imageUrl } from "../../../services/Constants";
import * as requestTrackingActions from "../../../actions/request.tracking.action";
import * as requestDeleteActions from "../../../actions/request.delete.actions";
import * as menuconfigActions from "../../../actions/menuconfig.action";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import styled from '@emotion/styled';
import {
    Stack,
    IconButton,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    CardContent,
    Card,
    TextField
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../..";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { server, TOKEN } from "../../../services/Constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue, red } from "@mui/material/colors";
import "./RequestTrackingHistoryPage.css";
import { Search as SearchIcon } from "@mui/icons-material";
import * as loginActions from "../../../actions/login.action";
import Container from '@mui/material/Container';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
// import SearchBar from "material-ui-search-bar";
// const CustomToolbar = (props:any) => {
//     return (
//         <Box>
//             <Grid>
//                 <SearchBar {...props} />
//             </Grid>
//         </Box>
//     )
// }



interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

export default function RequestTrackingHistoryPage(): JSX.Element {
    const { t, i18n } = useTranslation();
    const requesTrackingReducer = useSelector((state: RootReducers) => state.requesTrackingReducer);
    const dispatch = useAppDispatch();
    const [selectedRequest, setSelectedRequest] = React.useState<any>({});
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    // const [count_request, setCountRequest] = React.useState<number>(0);
    // const [count_operation, setCountOperation] = React.useState<number>(0);
    // const [count_succes, setCountSucces] = React.useState<number>(0);
    const [res2, setRes2] = React.useState<number>(0);
    const [res3, setRes3] = React.useState<number>(0);
    const [res6, setRes6] = React.useState<number>(0);
    const [res4, setRes4] = React.useState<number>(0);
    const [isSelected1, setIsSelected1] = React.useState(false);
    const [isSelected2, setIsSelected2] = React.useState(false);
    const [isSelected3, setIsSelected3] = React.useState(false);
    const [isSelected4, setIsSelected4] = React.useState(false);
    const navigate = useNavigate();
 

    //load data table
    React.useEffect(() => {
        if (!localStorage.getItem(TOKEN)) {
            //   navigate("/login");
            dispatch(loginActions.logouttologinpage(navigate));
            }
            dispatch(menuconfigActions.getMENU_CONFIGCheckTokenOnly(navigate));
            dispatch(requestTrackingActions.loadRequestTrackingHistory());

    }, []);
    React.useEffect(() => {
        setRes2(requesTrackingReducer.res2)
        setRes3(requesTrackingReducer.res3)
        setRes6(requesTrackingReducer.res6)
        setRes4(requesTrackingReducer.res4)

    }, [requesTrackingReducer]);

    const requestColumns: GridColDef[] = [
        {
            headerName: `${t('Request No.')}`,
            field: "request_no",
            flex: 1,
            minWidth: 100
        },
        {
            headerName: `${t('Date')}`,
            field: "create_date",
            flex: 1,
            minWidth: 100
        },
        {
            headerName: `${t('Due Date')}`,
            field: "request_date",
            flex: 1,
            minWidth: 100
        },
        {
            headerName: `${t('Firstname')} ${t('Lastname')}`,
            field: "name",
            flex: 2,
            minWidth: 150
        },
        {
            headerName: `${t('Request Type')}`,
            field: "request_name_show",
            flex: 2,
            minWidth: 150
        },
        {
            headerName: `${t('Status')}`,
            field: "status",  
            flex: 1,
            minWidth: 100         
        },
        {
            headerName: "",
            field: ".",
            maxWidth: 200,
            align: "center",
            renderCell: ({ row }: GridRenderCellParams<string>) => (
                <Stack direction="row">
                    
                        <>
                            <Button 
                                variant="contained"
                                onClick={ () =>{
                                    navigate("/my-request-tracking-select/H/" + row.id)
                                }}
                                size="small"
                                sx={{
                                    width: 80,
                                    backgroundColor: "#73777B",
                                    '&:hover': {backgroundColor: '#2C394B'},
                                }}
                            >
                                    {t(`Select`)}
                            </Button>
                        </>
                </Stack>
            ),
        },
    ];

    //search
    const [searchText, setSearchText] = React.useState("");
    const [searchText1, setSearchText1] = React.useState("");
    const [tableData, setTableData] = React.useState<any[]>([]);
    const [tableDataS, setTableDataS] = React.useState<any[]>([]);

    React.useEffect(() => {
        let  dataList:any[] = [];
        let index = 0;
        requesTrackingReducer.result.forEach((item) =>{
            if(Number(item.status_show) == 1){
                item.status = `${t('Draft')}`
            }else if(Number(item.status_show) == 2){
                item.status = `${t(`Not Started`)}`
            }else if(Number(item.status_show) == 3){
                item.status = `${t('In Progress')}`
            }else if(Number(item.status_show) == 4){
                item.status = `${t(`Done`)}`
            }else if(Number(item.status_show) == 5){
                item.status = `${t('Cancel')}`
            }else if(Number(item.status_show) == 6){
                item.status = `${t('ฺOverdue')}`
            }else if(Number(item.status_show) == 7){
                item.status = `${t('Pending')}`
    
            }else if(Number(item.status_show) == 8){
                item.status = `${t('Assign')}`
            }else if(Number(item.status_show) == 9){
                item.status = `${t('Closed')}`
            }
            if(i18next.language == 'en'){
                item.request_name_show = item.request_type_name_en;
            }else if(i18next.language == 'th'){
                item.request_name_show = item.request_type_name;
            }else{
                return;
            }
            dataList[index] = item;
            index += 1;
        })
        console.log("dataList")
        console.log(dataList)
        setTableData(dataList)
        setTableDataS(dataList)
    }, [requesTrackingReducer.result,i18next.language]);

    const requestSearch = (searchValue: string) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = requesTrackingReducer.result.filter((o: any) => {
          return Object.keys(o).some((k: any) => {
            return searchRegex.test(o[k].toString());
          });
        });
        setTableData(filteredRows);
    };

    // const cancelSearch = () => {
    //     setSearchText("");
    //     requestSearch(searchText);
    // };
    const StyledCard = styled(Card)`
        transition: border 0.3s;
        &:hover {
            border: 2px solid blue;
        }
        cursor: pointer;
      
        ${(props: { isSelected: boolean }) =>
          props.isSelected &&
          `
          border: 2px solid blue;
        `}

        `;
    const handleCardClick = (status:number) => {
        let dataSearchList:any = [] ;
        let check_click = false;
        if(status == 2 ){
            check_click = isSelected1;
            if(isSelected1){
                setIsSelected1(false);
                setIsSelected2(false);
                setIsSelected3(false);
                setIsSelected4(false);
            }else{
                setIsSelected1(true);
                setIsSelected2(false);
                setIsSelected3(false);
                setIsSelected4(false);
            }
        }
        if(status == 3 ){
            check_click = isSelected2;
            if(isSelected2){
                setIsSelected1(false);
                setIsSelected2(false);
                setIsSelected3(false);
                setIsSelected4(false);
            }else{
                setIsSelected1(false);
                setIsSelected2(true);
                setIsSelected3(false);
                setIsSelected4(false);
            }
        }
        if(status == 6 ){
            check_click = isSelected3;
            if(isSelected3){
                setIsSelected1(false);
                setIsSelected2(false);
                setIsSelected3(false);
                setIsSelected4(false);
            }else{
                setIsSelected1(false);
                setIsSelected2(false);
                setIsSelected3(true);
                setIsSelected4(false);
            }
        }
        if(status == 4){
            check_click = isSelected4;
            if(isSelected4){
                setIsSelected1(false);
                setIsSelected2(false);
                setIsSelected3(false);
                setIsSelected4(false);
            }else{
                setIsSelected1(false);
                setIsSelected2(false);
                setIsSelected3(false);
                setIsSelected4(true);
            }
        }
        if(check_click){
            dataSearchList = tableDataS;
        }else{
            tableDataS.forEach((item)=>{
                if(Number(item.status_show) == Number(status)){
                    dataSearchList.push(item)
                }
            })
        }
        setTableData(dataSearchList)
    };
    const showDialog = () => {
        if (selectedRequest === null) {
            return "";
        }
    };

    return (
        <Container fixed sx={{ mt: 3}} >
            <Box sx={{}}>
                {/* Summary Icons */}
                <Grid 
                    container item
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        margin: 'auto',
                        width: '100%',
                        maxWidth: '60rem'
                    }}
                >
                    <Grid display="flex" justifyContent="center" alignItems="center" xs={8} sm={4} md={3} lg={3} xl={3}>
                        <StyledCard 
                            isSelected={isSelected1}
                            onClick={
                                () => handleCardClick(2)
                            }
                            variant="outlined" 
                            sx={{
                                width: '100%',
                                border: '#73777B solid 2px',
                                borderRadius: 0,
                                color: '#73777B',
                                margin: '1rem'
                            }}
                        >
                            <CardContent 
                            className="CardContent" 
                            sx={{ 
                                padding: '0.5rem',
                                textAlign: 'center',
                                backgroundColor: '#FDEEDC',
                            }}
                            >
                            <Typography variant="h3" component="span">
                                {res2}
                            </Typography>
                            <Typography variant="body1">
                                {t(`Not Started`)}
                            </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                    <Grid display="flex" justifyContent="center" alignItems="center" xs={8} sm={4} md={3} lg={3} xl={3}>
                        <StyledCard 
                            isSelected={isSelected2}
                            variant="outlined"
                            onClick={
                                () => handleCardClick(3)
                            }
                            sx={{
                                width: '100%',
                                border: '#73777B solid 2px',
                                borderRadius: 0,
                                color: '#73777B',
                                margin: '1rem'
                            }}
                        >
                            <CardContent className="CardContent"
                                sx={{ 
                                    padding: '0.5rem',
                                    textAlign: 'center',
                                    backgroundColor: '#FFF4CF',
                                }}
                            >
                            <Typography variant="h3" component="span">
                                {res3}
                            </Typography>
                            <Typography variant="body1">
                                {t(`In Progress`)}
                            </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                    <Grid display="flex" justifyContent="center" alignItems="center" xs={8} sm={4} md={3} lg={3} xl={3}>
                        <StyledCard 
                            onClick={
                                () => handleCardClick(6)
                            }
                            isSelected={isSelected3}
                            variant="outlined"
                            sx={{
                                width: '100%',
                                border: '#73777B solid 2px',
                                borderRadius: 0,
                                color: '#73777B',
                                margin: '1rem'
                            }}
                        >
                            <CardContent className="CardContent"
                            sx={{ 
                                padding: '0.5rem',
                                textAlign: 'center',
                                backgroundColor: '#FFDAB9',
                            }}
                            >
                            <Typography variant="h3" component="span">
                                {res6}
                            </Typography>
                            <Typography variant="body1">
                                {t(`Overdue`)}
                            </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                    <Grid display="flex" justifyContent="center" alignItems="center" xs={8} sm={4} md={3} lg={3} xl={3}>
                        <StyledCard 
                            isSelected={isSelected4}
                            onClick={
                                () => handleCardClick(4)
                            }
                            variant="outlined"
                            sx={{
                                width: '100%',
                                border: '#73777B solid 2px',
                                borderRadius: 0,
                                color: '#73777B',
                                margin: '1rem'
                            }}
                        >
                            <CardContent className="CardContent"
                            sx={{ 
                                padding: '0.5rem',
                                textAlign: 'center',
                                backgroundColor: '#D6E4E5',
                            }}
                            >
                            <Typography variant="h3" component="span">
                                {res4}
                            </Typography>
                            <Typography variant="body1">
                                {t(`Done`)}
                            </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                </Grid>

                <br />
                <br />
                <br />
                <Grid container >
        
                    <Grid item xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                    >
                        <TextField 
                            fullWidth
                            placeholder="ค้นหา..."
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (<IconButton><SearchIcon /></IconButton>)
                            }}
                            onChange={(e)=>{
                                requestSearch(e.target.value)
                                setSearchText(e.target.value)
                            }} 
                            sx={{
                                "& .MuiInput-underline:before": {
                                    borderBottom: 1,
                                    borderColor: "divider",
                                },
                                marginBottom:2,
                                marginTop:1
                            }}
                        />
                    </Grid>
        
                    <Grid item xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                    ></Grid>
                </Grid>
                <Grid container >
        
                    <Grid item xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >            
                        <DataGrid
                            // components={{ Toolbar: CustomToolbar }}
                            sx={{ backgroundColor: "white"}}  
                            rows={tableData}
                            columns={requestColumns}
                            // componentsProps={{
                            //     toolbar: {
                            //       value: searchText,
                            //       onChange: (searchVal: string) => requestSearch(searchVal),
                            //       onCancelSearch: () => cancelSearch()
                            //     }
                            // }}
                            autoHeight
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                        />
                    
                        <br />
                        <br />
                        {showDialog()}
                    </Grid>
                </Grid>
            </Box>
        </Container>
  );
}
